import { createSlice } from '@reduxjs/toolkit';

const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {
    search: '',
  },
  reducers: {
    searchLocations: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { searchLocations } = gallerySlice.actions;

export default gallerySlice.reducer;
