import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { makeStyles } from '@material-ui/core';
import getPointPreviewURL, {
  getPointCubePreviewUrl,
  getStagingPointPreviewURL,
} from '~/utils/getPointPreviewURL';
import { existsOnServer } from '~/services/api';

const useStyles = makeStyles(theme => ({
  smallTooltip: {
    borderRadius: '3px',
    transform: 'translate(-50%, -50%)',
    width: '50px',
    height: '30px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '3.125rem',
      height: '1.875rem',
    },
  },
  bigTooltip: {
    transform: 'translate(-50%, -100%)',
    borderRadius: '3px',
    width: '187px',
    paddingBottom: '20px',
    transition: 'opacity, left 0.1s ease',
    display: 'block',
    zIndex: 10,

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '11.69rem',
      height: '9.06rem',
      paddingBottom: '1.25rem',
    },
  },
  tooltipImg: {
    height: '70px',
    width: '100%',
    objectFit: 'cover',
    position: 'relative',

    [theme.breakpoints.up('hiRes')]: {
      height: '4.375rem',
    },
  },
  smallTriangle: {
    position: 'absolute',
    top: '20px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      top: '1.25rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  verySmallTriangle: {
    position: 'absolute',
    top: '-6px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #363636',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '-0.375rem',
      borderLeft: '0.625rem solid transparent',
      borderRight: '0.625rem solid transparent',
      borderBottom: '0.625rem solid #363636',
    },
  },
  bigTriangle: {
    position: 'absolute',
    bottom: '5px',
    /* top: '120px', */
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '7.5rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  bigTooltipPaper: {
    padding: '5px 5px 15px',
    pointerEvents: 'none',
    background: '#36A6FD',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.3rem 0.3rem 1rem',
    },
  },
  activeNumber: {
    transform: 'translateX(-50%)',
    bottom: '4px',
    pointerEvents: 'none',
    height: '12px',
    color: '#36A6FD',
    fontSize: '0.625rem',

    [theme.breakpoints.up('hiRes')]: {
      buttom: '0.25rem',
      height: '0.75rem',
    },
  },
  bigCircle: {
    width: '20px',
    height: '20px',
    background: 'rgb(54, 166, 253, 0.2)',
    top: '40px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
      height: '1.25rem',
      top: '2.5rem',
    },
  },
  mediumCircle: {
    width: '14px',
    height: '14px',
    background: 'rgb(54, 166, 253, 0.4)',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.875rem',
      height: '0.875rem',
    },
  },
  smallCircle: {
    width: '7px',
    height: '7px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.5rem',
      height: '0.5rem',
    },
  },
  topPointImg: {
    maxWidth: '200px',

    [theme.breakpoints.up('hiRes')]: {
      maxWidth: '12.5rem',
    },
  },
  value: {
    fontSize: '1.25rem',
    lineHeight: '2rem',

    [theme.breakpoints.up('lowRes')]: {
      fontSize: '1.5rem',
    },
  },
}));
// {show: false, caretX: 0, caretY: 0, scrollX: 0, }
function PointInfo({
  pointId,
  locationId,
  name,
  parentId,
  tooltipInfo,
  setTooltipInfo,
}) {
  const classes = useStyles();
  const [src, setSrc] = useState();
  const [loaded, setLoaded] = useState(false);

  const checkPreview = async () => {
    const [existsInLocation, existsInParentLocation] = await Promise.all([
      existsOnServer(getPointPreviewURL(pointId + 1, locationId)),
      existsOnServer(getPointPreviewURL(pointId + 1, parentId)),
    ]);

    if (existsInLocation) {
      return setSrc(getPointPreviewURL(pointId + 1, locationId));
    }

    if (existsInParentLocation) {
      return setSrc(getPointPreviewURL(pointId + 1, parentId));
    }

    const existsInStagingLocation = await existsOnServer(
      getStagingPointPreviewURL(pointId + 1, locationId),
    );

    if (existsInStagingLocation) {
      return setSrc(getStagingPointPreviewURL(pointId + 1, locationId));
    }

    const cubePreviewUrl = getPointCubePreviewUrl(pointId + 1, locationId);
    const existsCubePreview = await existsOnServer(cubePreviewUrl);

    if (existsCubePreview) {
      setSrc(cubePreviewUrl);
    }
  };

  useEffect(() => {
    checkPreview();

    return () => {
      setSrc(undefined);
      setLoaded(false);
    };
  }, [checkPreview, locationId, parentId, pointId]);

  return (
    <a
      href={`${process.env.PLAYER_URL}?locationId=${locationId}&point_id=${
        pointId + 1
      }`}
      target="_blank"
      rel="noopener noreferrer"
      onFocus={() => setTooltipInfo({ ...tooltipInfo, show: true })}
      onMouseLeave={() => setTooltipInfo({ ...tooltipInfo, show: false })}
      className={`absolute font-bold text-xs text-white flex justify-center items-center ${classes.bigTooltip}`}
      style={{
        visibility: tooltipInfo.show ? 'visible' : 'hidden',
        left: tooltipInfo.caretX - tooltipInfo.scrollX + 20,
        top: tooltipInfo.caretY + tooltipInfo.offsetY,
        // pointerEvents: 'click'
      }}
    >
      <div className={`w-full h-full ${classes.bigTooltipPaper}`}>
        <div
          className={classes.bigTriangle}
          style={{
            transform: tooltipInfo.center
              ? 'translateX(-50%)'
              : 'translateX(calc(-50% + 60px))',
          }}
        />
        <div
          className="w-full flex justify-between font-bold pt-1"
          style={{ fontSize: '0.75rem' }}
        >
          <div>Point #{pointId + 1}</div>
          <div>Clicks: {tooltipInfo.value.eventsCount}</div>
        </div>
        <div
          style={{
            marginBottom: '5px',
            margintop: '-3px',
            fontSize: '0.5625rem',
            fontWeight: '500',
          }}
        >
          {name}
        </div>
        {!loaded && (
          <Skeleton
            height={70}
            style={{ position: 'relative', bottom: '4px' }}
          />
        )}
        <img
          alt=""
          className={classes.tooltipImg}
          style={{
            display: loaded ? 'inline' : 'none',
          }}
          src={src}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </a>
  );
}

export default PointInfo;
