import moment from 'moment';

export function formatDateFromSeconds(duration) {
  const d = moment.duration(duration, 'seconds');
  const w = Math.floor(d.asWeeks());
  d.subtract(w, 'w');
  const day = Math.floor(d.asDays());
  d.subtract(day, 'd');
  const h = Math.floor(d.asHours());
  const m = d.minutes();
  const s = d.seconds();

  let result = '';
  let count = 0;
  if (w && count < 3) {
    count++;
    result += `${w}w `;
  }
  if (day && count < 3) {
    count++;
    result += `${day}d `;
  }
  if (h && count < 3) {
    count++;
    result += `${h}h `;
  }
  if (m && count < 3) {
    count++;
    result += `${m}m `;
  }
  if (s && count < 3) {
    count++;
    result += `${s}s `;
  }

  return result;
}
