import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const datePicker = createSlice({
  name: 'datePicker',
  initialState: {
    visible: false,
    range: [moment(), moment()],
    useCustomRange: false,
    initialRange: [moment(), moment()],
  },
  reducers: {
    changeVisible: (state, action) => {
      state.visible = action.payload;
    },
    trySetRange: (state, action) => {
      if (state.useCustomRange) {
        return state;
      }

      return { ...state, range: action.payload };
    },
    setRange: (state, action) => {
      return { ...state, range: action.payload };
    },
    setInitialRange: (state, action) => {
      return { ...state, initialRange: action.payload };
    },
    setUseCustomRange: (state, action) => {
      return { ...state, useCustomRange: action.payload };
    },
  },
});

export const {
  changeVisible,
  setRange,
  setInitialRange,
  trySetRange,
  setUseCustomRange,
} = datePicker.actions;

export default datePicker.reducer;
