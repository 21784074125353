/* eslint-disable prettier/prettier */
import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { Bar, Line } from 'react-chartjs-2';
import { Link, NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { makeStyles, Button } from '@material-ui/core';

import clsx from 'clsx';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import _, { isEmpty, toLower } from 'lodash';

import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import { authRoles } from '~/constants';
import {
  getCompanyLocationStats,
  getAllVisitsStats,
  getAllViewDurationStats,
  getAllStats,
  getCompanyViewDurationStats,
  getCompanyVisitsStats,
  getCompanyFullStats,
  getCompaniesFullStats,
} from '~/services/api';
import { getSubscriptionById } from '~/services/stripeApi';
import * as allMultitourStatsStore from '~/store/allMultitourStats';
import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';
import {
  setDurationTooltipInfo,
  updateDurationTooltipInfo,
} from '~/store/durationTooltip';
import { setInitialRange, trySetRange } from '~/store/datePicker';
import { formatDateFromSeconds } from '~/utils/helper';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';
import {
  calculateEventsInIntervals,
  filterStatsByDate,
} from '~/views/private/overview/utils';
import {
  timeConverter,
  qBTimeConverter,
  getTimePeriod,
} from '~/views/private/billings/utils';

import useFetchQuickBooksData from '~/hooks/useFetchQuickBooksData';
import {
  ViewsByDevicesInfoCard,
  TimeStep,
  MultiDateRangePicker,
  InfoCard,
  GraphTooltip,
} from './Components';
import { RANGE_TYPE, TOOLTIP_TYPE } from './constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  popularTourCard: {
    padding: '6px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
  tooltip: {
    position: 'absolute',
    left: '0',
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '4px',
    transform: 'translate(6px, 8px)',
    visibility: 'hidden',
    padding: '6px',
    zIndex: 1,
    whiteSpace: 'nowrap',

    [theme.breakpoints.up('hiRes')]: {
      transform: 'translate(1rem, 1.25rem)',
      padding: '0.375rem',
    },
  },
  tooltipArrow: {
    width: '0px',
    height: '0px',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    position: 'absolute',
    top: '-5px',
    left: '0',
    transform: 'translateX(50%)',

    [theme.breakpoints.up('hiRes')]: {
      borderLeft: '0.3125rem solid transparent',
      borderRight: '0.3125rem solid transparent',
      borderBottom: '0.3125rem solid #2f2f2f',
      top: '-0.28rem',
    },
  },
  unpaidImg: {
    '&:hover + span': {
      visibility: 'visible',
    },
  },
  paidItem: {
    cursor: 'default',
    '& .tooltip': {
      position: 'absolute',
      left: '0',
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
      borderRadius: '4px',
      transform: 'translate(30px, 20px)',
      visibility: 'hidden',
      padding: '6px',

      [theme.breakpoints.up('hiRes')]: {
        transform: 'translate(1.875rem, 1.25rem)',
        padding: '0.375rem',
      },
    },
    '& .tooltip>.tooltip-arrow': {
      width: '0px',
      height: '0px',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '5px solid #2f2f2f',
      position: 'absolute',
      top: '-5px',
      left: '0',
      transform: 'translateX(50%)',

      [theme.breakpoints.up('hiRes')]: {
        borderLeft: '0.3125rem solid transparent',
        borderRight: '0.3125rem solid transparent',
        borderBottom: '0.3125rem solid #2f2f2f',
        top: '-0.28rem',
      },
    },
    '&:hover .scroll': {
      visibility: 'visible',
    },
  },
  upload: {
    background: 'rgba(54, 166, 253, 0.15)',
    borderRadius: 6,
    padding: '3px 4px',
    width: '24px',
    display: 'flex',
    marginLeft: 6,
    height: '24px',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.4rem',
      height: '1.4rem',
      padding: '0.28rem',
    },
  },
  header: {
    fontSize: '1.5rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1.33rem',
    },
  },
  header2: {
    fontSize: '0.875rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },
  },
  mostPopularTours: {
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.6rem 0.9rem 1rem',
    },
  },
  popularImg: {
    borderRadius: '10px',
    height: '64px',

    [theme.breakpoints.up('hiRes')]: {
      height: '3.8rem',
    },
  },
  h12: {
    height: '270px',

    [theme.breakpoints.up('hiRes')]: {
      height: '12vw',
    },
  },
  h14: {
    height: '330px',

    [theme.breakpoints.up('hiRes')]: {
      height: '14vw',
    },
  },
  paymentContainer: {
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    // maxHeight: '690px',
    height: '0px',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.6rem 1.2rem',
    },
  },
  activeNumber: {
    transform: 'translateX(-50%)',
    bottom: '4px',
    pointerEvents: 'none',
    height: '12px',
    color: '#36A6FD',
    fontSize: '0.625rem',

    [theme.breakpoints.up('hiRes')]: {
      bottom: '0.25rem',
      height: '0.75rem',
    },
  },
  smallTooltip: {
    borderRadius: '3px',
    transform: 'translate(-50%, -135%)',
    width: '50px',
    height: '30px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '3.125rem',
      height: '1.875rem',
    },
  },
  smallTriangle: {
    position: 'absolute',
    top: '20px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      top: '1.25rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  bigCircle: {
    width: '20px',
    height: '20px',
    background: 'rgb(54, 166, 253, 0.2)',
    top: '40px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
      height: '1.25rem',
      top: '2.5rem',
    },
  },
  mediumCircle: {
    width: '14px',
    height: '14px',
    background: 'rgb(54, 166, 253, 0.4)',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.875rem',
      height: '0.875rem',
    },
  },
  smallCircle: {
    width: '7px',
    height: '7px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.5rem',
      height: '0.5rem',
    },
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  skeletonContent: {
    textAlign: 'center',
    color: 'grey',
    margin: '20px',
  },
}));

const charts = {
  VIEWS: 'Views',
  VIEW_DURATION: 'View Duration',
};

function checkOverflow(id) {
  const link = document.getElementById(`payment-status-link-${id}`);
  if (link.offsetWidth < link.scrollWidth) {
    const tooltip = document.getElementById(`payment-status-tooltip-${id}`);
    tooltip.classList.add('scroll');
  }
}

function deleteClass(id) {
  const tooltip = document.getElementById(`payment-status-tooltip-${id}`);
  tooltip.classList.remove('scroll');
}

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradient.addColorStop(0, 'rgba(54,166,253,0.5)');
viewDurationGradient.addColorStop(1, 'rgba(54,166,253,0.25)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradientHover.addColorStop(0, 'rgba(54,166,253,1)');
viewDurationGradientHover.addColorStop(1, 'rgba(54,166,253,0.49)');

function ViewDurationChart() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const refDuration = useRef();
  const scrollRef = useRef();

  const user = useSelector(state => state.auth.user);
  const viewDurationDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;
  const isAdmin = user.role === authRoles.ADMIN;

  const [isTitles, setIsTitles] = useState({});

  const [filteredViewDurationStats, setFilteredViewDurationStats] =
    useState(null);

  // const [currentStepViewDuration, setCurrentStepViewDuration] = useState(
  //   RANGE_TYPE.WEEK,
  // );

  const viewDurationStats = useAsync(async () => {
    try {
      if (isAdmin) return await getAllViewDurationStats();
      return await getCompanyViewDurationStats(user.company);
    } catch (e) {
      console.error('overview:viewDurationStats', e);
      return [];
    }
  });

  const changeTimestampHandler = useCallback(() => {
    scrollRef.current.scrollLeft = 0;
  }, []);

  useEffect(() => {
    if (!isTitles.chart) return;
    const { canvas, chart } = isTitles;
    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);
      const maxWidth = 750;
      const maxHeight = 500;
      const ratioOfList = 210 / 297;
      const ratio = canvas.height / canvas.width;
      const docDefinition =
        ratio < ratioOfList
          ? {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxWidth,
                  height: maxWidth * ratio,
                },
              ],
              styles: {
                header: {
                  fontSize: 35,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            }
          : {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxHeight / ratio,
                  height: maxHeight,
                },
              ],
              styles: {
                header: {
                  fontSize: 40,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  useEffect(() => {
    if (
      !viewDurationStats.loading &&
      viewDurationStats.value &&
      viewDurationDateValue &&
      viewDurationDateValue.length !== 0
    )
      setFilteredViewDurationStats(
        filterStatsByDate(
          viewDurationStats.value,
          viewDurationDateValue,
        ).slice(),
      );
  }, [viewDurationStats, viewDurationDateValue]);

  useEffect(() => {
    if (!viewDurationStats.loading && viewDurationDateValue) {
      const dataViewDuration = calculateEventsInIntervals({
        data: viewDurationStats.value.slice().map(({ eventDate, duration }) => {
          return {
            eventDate,
            value: duration,
          };
        }),
        range: currentStep,
        start: viewDurationDateValue[0].format('YYYY-MM-DD'),
        end: viewDurationDateValue[1].format('YYYY-MM-DD'),
      });

      const asd = dataViewDuration.reverse().map(({ eventDate, value }) => ({
        label: moment(eventDate).format('DD.MM'),
        data: {
          eventDate,
          duration: value,
        },
      }));

      setFilteredViewDurationStats(
        filterStatsByDate(asd, viewDurationDateValue).slice(),
      );
    }
  }, [currentStep, viewDurationDateValue, viewDurationStats]);

  if (
    !viewDurationStats.value ||
    !viewDurationDateValue ||
    viewDurationDateValue.length === 0 ||
    !filteredViewDurationStats
  ) {
    return null;
  }

  return (
    <>
      <div
        className="font-bold bg-white flex justify-between"
        style={{
          padding: '18px 0',
        }}
      >
        <div
          className="flex"
          style={{
            marginTop: 80,
            marginBottom: 18,
            fontSize: '0.75rem',
            alignItems: 'center',
          }}
        >
          View Duration
          <div
            role="button"
            className={classes.upload}
            style={{
              marginRight: '1rem',
            }}
            onClick={() =>
              setIsTitles({
                canvas: refDuration.current.chartInstance.canvas,
                chart: charts.VIEW_DURATION,
              })
            }
          >
            <img src="/static/icons/upload.svg" alt="Upload" />
          </div>
          <div>
            <TimeStep
              onClick={changeTimestampHandler}
              // setCurrentStep={setCurrentStepViewDuration}
              visitsDateValue={viewDurationDateValue}
            />
          </div>
        </div>
      </div>
      <div
        className={`w-full relative flex flex-col ${classes.h14}`}
        ref={scrollRef}
        style={{
          overflow: 'auto',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: '98%',
            maxWidth:
              filteredViewDurationStats.length < 3
                ? 'calc(100% - 5px)'
                : 'none',
            minWidth: filteredViewDurationStats.length * size(16),
            overflowY: 'overlay',
          }}
        >
          <Bar
            ref={refDuration}
            data={{
              labels: filteredViewDurationStats.map(item => item.label),
              datasets: [
                {
                  backgroundColor: viewDurationGradient,
                  hoverBackgroundColor: viewDurationGradientHover,
                  data: filteredViewDurationStats.map(
                    item => item.data.duration / 60,
                  ),
                  label: '',
                  datasetLabel: '',
                },
              ],
            }}
            options={{
              animation: false,
              cornerRadius: 20,
              layout: { padding: 0 },
              legend: { display: false },
              maintainAspectRatio: false,
              responsive: true,
              devicePixelRatio: window.devicePixelRatio * 2,

              onHover(e, [chart]) {
                if (!chart) {
                  dispatch(
                    updateDurationTooltipInfo({
                      show: false,
                    }),
                  );

                  return;
                }

                const value = filteredViewDurationStats[chart._index].data;

                dispatch(
                  setDurationTooltipInfo({
                    show: true,
                    bigTooltipText: `Duration: ${moment
                      .utc(value.duration * 1000)
                      .format('HH:mm:ss')}`,
                    type: TOOLTIP_TYPE.SMALL,
                    pointId: value.pointId,
                    smallTooltipText: `${Math.round(value.duration / 60)}m`,
                    tooltipTop:
                      chart._view.y +
                      e.target.parentElement.parentElement.offsetTop -
                      30,
                    tooltipLeft:
                      chart._view.x -
                      e.target.parentElement.parentElement.scrollLeft +
                      40,
                  }),
                );
              },
              tooltips: {
                position: 'nearest',
                enabled: false,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: size(8),
                      reverse: true,
                    },
                    categoryPercentage: 0.97,
                    barPercentage: 0.97,
                    barThickness: 14,
                    scaleLabel: {
                      display: true,
                      labelString: 'Date',
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontStyle: 'bold',
                      fontColor: '#000',
                      stepSize: 250,
                      beginAtZero: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'Time (m)',
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
      <GraphTooltip store="durationTooltip" />
    </>
  );
}

const OverviewView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const refVisits = useRef();
  const secondColRef = useRef();
  const scrollRef = useRef();

  const user = useSelector(state => state.auth.user);
  const loaded = useSelector(state => state.allMultitourStats.loaded);
  const allMultitourStats = useSelector(state => state.allMultitourStats.value);
  const allMultitourStatsLoaded = useSelector(
    state => state.allMultitourStats.loaded,
  );
  const visitsDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;

  const [multiToursPaidStatusListData, setMultiToursPaidStatusListData] =
    useState([]);
  const [isTitles, setIsTitles] = useState({});
  // const [currentStepVisits, setCurrentStepVisits] = useState(RANGE_TYPE.WEEK);
  const [filteredVisitStats, setFilteredVisitStats] = useState();
  const [isLoad, setIsLoad] = useState({});

  const { qbData, loading } = useFetchQuickBooksData();

  const isAdmin = user.role === authRoles.ADMIN;

  const stats = useAsync(async () => {
    try {
      if (isAdmin) return await getAllStats();
      return await getCompanyLocationStats(user.company);
    } catch (e) {
      console.error('overview:stats', e);

      return {
        totalVisitsMobile: 0,
        totalVisits: 0,
        totalCustomButtonClicks: 0,
        avgVisitsPerDay: 0,
        avgVisitsPerMonth: 0,
        totalDuration: 0,
        avgVisitDuration: 0,
      };
    }
  });

  const visitStats = useAsync(async () => {
    try {
      if (isAdmin) return await getAllVisitsStats();
      return await getCompanyVisitsStats(user.company);
    } catch (e) {
      console.error('overview:visitStats', e);
      return [];
    }
  });

  const mobileVisitsPercent = useMemo(() => {
    if (stats.value == null) return 0;

    return (
      (100 * stats.value.totalVisitsMobile) /
      (stats.value.totalVisitsMobile + stats.value.totalVisits)
    );
  }, [stats.value]);

  const desktopVisitsPercent = useMemo(() => {
    return 100 - mobileVisitsPercent;
  }, [mobileVisitsPercent]);

  const mostPopularTours = useMemo(() => {
    return allMultitourStats.slice(0, 2);
  }, [allMultitourStats]);

  const getSubscriptionData = async tourData => {
    const { subscription_id, qb_recurring_transaction_id } =
      tourData.mainLocation;
    const subscription = subscription_id
      ? await getSubscriptionById(subscription_id)
      : {};

    return {
      ...tourData,
      subscription,
      qb_recurring_transaction_id,
      paymentDetails:
        subscription.status === 'active'
          ? {
              isPaid: true,
              startDate: timeConverter(
                subscription.current_period_start,
                'MM/DD/YYYY',
              ),
              dueDate: timeConverter(
                subscription.current_period_end,
                'MM/DD/YYYY',
              ),
            }
          : {
              isPaid: false,
              startDate: timeConverter(
                subscription.current_period_start,
                'MM/DD/YYYY',
              ),
              dueDate: timeConverter(
                subscription.current_period_end,
                'MM/DD/YYYY',
              ),
            },
    };
  };

  const getTourData = tours => {
    return Promise.all(tours.map(getSubscriptionData));
  };

  const getInvoiceById = (qbData, id) => {
    return qbData.QueryResponse.RecurringTransaction.find(
      invoice => invoice.Invoice.Id === id,
    );
  };

  const multiToursPaidStatusList = useMemo(() => {
    return allMultitourStats.map(({ multilocationName, mainLocation }) => ({
      multilocationName,
      mainLocation,
    }));
  }, [allMultitourStats]);

  const conversion = useMemo(() => {
    return allMultitourStats.reduce(
      (acc, tour) => acc + (tour.stats.totalHotspotButtonClicks || 0),
      0,
    );
  }, [allMultitourStats]);

  const changeTimestampHandler = useCallback(() => {
    scrollRef.current.scrollLeft = 0;
  }, []);

  useEffect(() => {
    if (!isTitles.chart) return;
    const { canvas, chart } = isTitles;
    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);
      const maxWidth = 750;
      const maxHeight = 500;
      const ratioOfList = 210 / 297;
      const ratio = canvas.height / canvas.width;
      const docDefinition =
        ratio < ratioOfList
          ? {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxWidth,
                  height: maxWidth * ratio,
                },
              ],
              styles: {
                header: {
                  fontSize: 35,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            }
          : {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxHeight / ratio,
                  height: maxHeight,
                },
              ],
              styles: {
                header: {
                  fontSize: 40,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  useEffect(() => {
    if (!loaded) {
      (isAdmin ? getCompaniesFullStats() : getCompanyFullStats(user.company))
        .then(result => dispatch(allMultitourStatsStore.load(result)))
        .catch(console.error);
    }
  }, [dispatch, isAdmin, loaded, user.company]);

  useEffect(() => {
    if (
      visitsDateValue &&
      !visitStats.loading &&
      visitStats.value &&
      visitsDateValue.length !== 0
    )
      setFilteredVisitStats(
        filterStatsByDate(visitStats.value, visitsDateValue).slice(),
      );
  }, [visitStats, visitsDateValue]);

  useEffect(() => {
    if (
      !visitStats.loading &&
      visitStats.value &&
      Array.isArray(visitStats.value)
    ) {
      const val = _.get(visitStats.value, '[0].eventDate');

      const realVal = moment(val || new Date(Date.now() - 3600 * 1000));

      dispatch(trySetRange(defaultTimeRange(realVal)));
      dispatch(setInitialRange(defaultTimeRange(realVal)));
    }
  }, [dispatch, visitStats]);

  useEffect(() => {
    if (visitStats.value && visitsDateValue) {
      const dataVisits = calculateEventsInIntervals({
        data: visitStats.value.slice().map(({ eventDate, eventsCount }) => {
          return {
            eventDate,
            value: eventsCount,
          };
        }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredVisitStats(
        dataVisits.reverse().map(({ eventDate, value }) => ({
          eventDate,
          eventsCount: value,
        })),
      );
    }
  }, [currentStep, visitStats, visitsDateValue]);

  useEffect(() => {
    if (multiToursPaidStatusList.length > 0) {
      getTourData(multiToursPaidStatusList).then(
        setMultiToursPaidStatusListData,
      );
    }
  }, [multiToursPaidStatusList]);

  if (!stats.value || !visitStats.value || !filteredVisitStats) {
    return null;
  }

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '26px',
          }}
        >
          <div>
            <div className={classes.header}>OVERVIEW</div>
            <div className={classes.header2}>Multi-Tour Analytics</div>
          </div>
        </div>
      </Header>
      <div
        className="w-full flex flex-col lg:flex-row"
        style={{ minHeight: '100%' }}
      >
        <div
          className="w-full lg:w-5/12 lg:pr-3 flex flex-col"
          style={{
            height: '100%',
          }}
        >
          <div
            className="w-full flex flex-wrap justify-between mb-1"
            style={{ width: 'calc(100% + 4px)', marginLeft: '-4px' }}
          >
            <InfoCard
              className="px-1"
              title="Conversion"
              tooltipText="Amounts of clicks to the Lease Now button at the bottom right corner"
              value={stats.value.totalCustomButtonClicks}
            />
            <InfoCard
              className="px-1"
              title="Custom Button Clicks"
              tooltipText="Amounts of clicks on the all buttons inside informational hotspots"
              value={conversion}
            />
          </div>

          <div className={`w-full bg-white ${classes.mostPopularTours}`}>
            <div
              className="flex font-bold"
              style={{ color: '#6C6C6C', marginBottom: '22px' }}
            >
              <div className="w-1/2 font-bold" style={{ fontSize: '0.875rem' }}>
                Most Popular Tours
              </div>
              <div
                className="w-1/4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                Views
              </div>
              <div
                className="w-1/4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                View Duration
              </div>
            </div>

            {!allMultitourStatsLoaded && <Skeleton height={162} />}

            {mostPopularTours.map(tour => {
              return (
                <NavLink
                  to={
                    tour.isRealMultitour
                      ? `/overview/multi/${tour.companyName}/${tour.multilocationName}`
                      : `/overview/location/${tour.id}`
                  }
                  key={tour.tourId + tour.multilocationName}
                  className={clsx('flex items-center', classes.popularTourCard)}
                  style={{ marginBottom: '10px' }}
                >
                  <div className="flex w-1/2 font-bold uppercase items-center">
                    <div
                      className="w-1/2 flex-shrink-0"
                      style={{
                        maxWidth: 150,
                        marginRight: '20px',
                        borderRadius: '10px',
                        flexShrink: 0,
                      }}
                    >
                      {!isLoad[tour.id] && <Skeleton height={64} />}
                      <img
                        className={classes.popularImg}
                        style={{
                          display: isLoad[tour.id] ? 'inline' : 'none',
                        }}
                        src={`${process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT}/locations/${tour.mainLocation.id}/preview.jpg`}
                        alt=""
                        onLoad={() =>
                          setIsLoad(prev => ({ ...prev, [tour.id]: true }))
                        }
                      />
                    </div>
                    <div>{tour.multilocationName}</div>
                  </div>
                  <div
                    className="w-1/4 text-center font-bold"
                    style={{ color: '#A0A0A0', fontSize: '1.125rem' }}
                  >
                    {tour.stats.totalVisits + tour.stats.totalVisitsMobile}
                  </div>
                  <div
                    className="w-1/4 text-center font-bold"
                    style={{ color: '#A0A0A0', fontSize: '1.125rem' }}
                  >
                    {formatDateFromSeconds(tour.stats.totalDuration)}
                  </div>
                </NavLink>
              );
            })}
          </div>

          <div
            className={`transform-gpu w-full bg-white mt-4 flex-1 flex flex-col relative ${classes.paymentContainer}`}
          >
            <div className="w-1/2 font-bold" style={{ fontSize: '0.875rem' }}>
              Payment Info
            </div>

            <div
              className="flex font-bold"
              style={{
                color: '#6C6C6C',
                marginTop: '10px',
                marginBottom: '22px',
                marginLeft: '-20px',
              }}
            >
              <div
                className="w-1/4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                Tour name
              </div>
              <div
                className="w-1/4 pl-4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                Status
              </div>
              <div className="w-1/2 flex">
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem' }}
                >
                  Start Date
                </div>
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem', marginLeft: '-4px' }}
                >
                  Due Date
                </div>
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem', marginLeft: '-4px' }}
                >
                  Interval
                </div>
              </div>
            </div>

            {/* {multiToursPaidStatusListData.length === 0 && ( */}
            {/*   <div className={classes.skeleton}> */}
            {/*     <Skeleton height="100%" /> */}
            {/*     <p className={classes.skeletonContent}>No data available</p> */}
            {/*   </div> */}
            {/* )} */}

            <div
              className="flex-1"
              style={{
                overflow: 'auto',
                maxHeight: '550px',
              }}
            >
              {(loading || multiToursPaidStatusListData.length === 0) && (
                <Skeleton height={162} />
              )}
              {!loading &&
                multiToursPaidStatusListData.map((tour, idx) => (
                  <div
                    key={tour.mainLocation.id}
                    className="flex"
                    style={{
                      color: '#6C6C6C',
                      marginTop: idx === 0 ? '0px' : '10px',
                    }}
                  >
                    <div
                      className={clsx(
                        'w-1/4 font-bold truncate',
                        classes.paidItem,
                      )}
                      style={{ color: '#333', fontSize: '0.6875rem' }}
                      onMouseEnter={() => checkOverflow(idx)}
                      onMouseLeave={() => deleteClass(idx)}
                    >
                      <div style={{ display: 'inline-flex', width: '100%' }}>
                        <span style={{ marginRight: '10px' }}>{idx + 1}</span>{' '}
                        <Link
                          style={{
                            overflow: 'hidden',
                            display: 'block',
                            textOverflow: 'ellipsis',
                          }}
                          id={`payment-status-link-${idx}`}
                          to={`/overview/multi/${tour.mainLocation.company_name}/${tour.multilocationName}`}
                        >
                          {tour.multilocationName}
                        </Link>
                      </div>
                      <span
                        className="tooltip"
                        id={`payment-status-tooltip-${idx}`}
                      >
                        <div className="tooltip-arrow" />
                        {tour.multilocationName}
                      </span>
                    </div>
                    <div
                      className="w-1/4 pl-4 flex justify-center items-center font-bold"
                      style={{ fontSize: '0.6875rem' }}
                    >
                      {!isEmpty(tour.subscription) &&
                      tour.subscription.status === 'active' ? (
                        <div
                          style={{
                            color:
                              !tour.subscription.pause_collection && '#29CC97',
                          }}
                        >
                          {!tour.subscription.pause_collection
                            ? tour.subscription.status
                            : 'paused'}
                        </div>
                      ) : (
                        <div>{tour.subscription.status}</div>
                      )}
                      {loading && <Skeleton height={162} />}
                      {tour.qb_recurring_transaction_id &&
                        !loading &&
                        getInvoiceById(qbData, tour.qb_recurring_transaction_id)
                          .Invoice.Balance === 0 && (
                          <div style={{ color: '#29CC97' }}>active</div>
                        )}
                      {tour.qb_recurring_transaction_id &&
                        !loading &&
                        getInvoiceById(qbData, tour.qb_recurring_transaction_id)
                          .Invoice.Balance !== 0 &&
                        new Date(
                          getInvoiceById(
                            qbData,
                            tour.qb_recurring_transaction_id,
                          ).Invoice.RecurringInfo.ScheduleInfo.NextDate,
                        )
                          .toISOString()
                          .split('T')[0] <
                          new Date().toISOString().split('T')[0] && (
                          <div>past_due</div>
                        )}
                      {tour.qb_recurring_transaction_id &&
                        !loading &&
                        getInvoiceById(qbData, tour.qb_recurring_transaction_id)
                          .Invoice.Balance !== 0 &&
                        new Date(
                          getInvoiceById(
                            qbData,
                            tour.qb_recurring_transaction_id,
                          ).Invoice.RecurringInfo.ScheduleInfo.NextDate,
                        )
                          .toISOString()
                          .split('T')[0] >=
                          new Date().toISOString().split('T')[0] && (
                          <div>pending</div>
                        )}
                      {isEmpty(tour.subscription) &&
                        !tour.qb_recurring_transaction_id && (
                          <div>no subscription</div>
                        )}
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{
                            marginLeft: '10px',
                            width: '0.75rem',
                            height: '0.75rem',
                          }}
                          src={`/static/icons/${
                            tour.subscription.status === 'active' ||
                            (tour.qb_recurring_transaction_id &&
                              !loading &&
                              getInvoiceById(
                                qbData,
                                tour.qb_recurring_transaction_id,
                              ).Invoice.Balance === 0)
                              ? 'payed'
                              : tour.subscription.status ||
                                (tour.qb_recurring_transaction_id &&
                                  !loading &&
                                  getInvoiceById(
                                    qbData,
                                    tour.qb_recurring_transaction_id,
                                  ).Invoice.Balance !== 0)
                              ? 'unpaid'
                              : 'no-subscription'
                          }.svg`}
                          alt={tour.paymentDetails.isPaid ? 'Paid' : 'Unpaid'}
                          className={classes.unpaidImg}
                        />
                        <span className={classes.tooltip}>
                          <div className={classes.tooltipArrow} />
                          Tour subscription status
                        </span>
                      </div>
                    </div>
                    <div className="w-1/2 flex">
                      <div
                        className="w-1/3 text-center"
                        style={{ fontSize: '0.6875rem' }}
                      >
                        {!isEmpty(tour.subscription)
                          ? tour.paymentDetails.startDate
                          : tour.qb_recurring_transaction_id
                          ? ''
                          : '-'}
                        {tour.qb_recurring_transaction_id &&
                          qBTimeConverter(
                            getInvoiceById(
                              qbData,
                              tour.qb_recurring_transaction_id,
                            ).Invoice.RecurringInfo.ScheduleInfo.StartDate,
                            'MM/DD/YYYY',
                          )}
                      </div>
                      <div
                        className="w-1/3 text-center"
                        style={{ fontSize: '0.6875rem' }}
                      >
                        {!isEmpty(tour.subscription)
                          ? tour.paymentDetails.dueDate
                          : tour.qb_recurring_transaction_id
                          ? ''
                          : '-'}
                        {tour.qb_recurring_transaction_id &&
                          qBTimeConverter(
                            getInvoiceById(
                              qbData,
                              tour.qb_recurring_transaction_id,
                            ).Invoice.RecurringInfo.ScheduleInfo.NextDate,
                            'MM/DD/YYYY',
                          )}
                      </div>
                      <div
                        className="w-1/3 text-center"
                        style={{ fontSize: '0.6875rem' }}
                      >
                        {!isEmpty(tour.subscription)
                          ? getTimePeriod(tour.subscription.plan.interval)
                          : tour.qb_recurring_transaction_id
                          ? ''
                          : '-'}
                        {tour.qb_recurring_transaction_id &&
                          !loading &&
                          getTimePeriod(
                            toLower(
                              getInvoiceById(
                                qbData,
                                tour.qb_recurring_transaction_id,
                              ).Invoice.RecurringInfo.ScheduleInfo.IntervalType,
                            ),
                          )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="w-full lg:w-7/12 flex flex-col" ref={secondColRef}>
          <div
            className="w-full flex flex-wrap justify-between mb-1"
            style={{ width: 'calc(100% + 4px)', marginLeft: '-4px' }}
          >
            <InfoCard
              className="px-1"
              title="Views"
              value={
                (stats.value.totalVisits || 0) +
                (stats.value.totalVisitsMobile || 0)
              }
            />
            <InfoCard
              className="px-1"
              title="Last Month Views"
              value={
                (stats.value.last30DaysVisits || 0) +
                (stats.value.last30DaysVisitsMobile || 0)
              }
            />
            <InfoCard
              className="px-1"
              title="Duration"
              value={formatDateFromSeconds(stats.value.totalDuration)}
            />
            <ViewsByDevicesInfoCard
              mobilePercent={mobileVisitsPercent}
              desktopPercent={desktopVisitsPercent}
              mobileCount={stats.value.totalVisitsMobile}
              desktopCount={stats.value.totalVisits}
            />
          </div>

          <div
            style={{
              position: 'relative',
              borderRadius: '10px',
              padding: '0 40px',
              paddingRight: '60px',
              paddingBottom: 20,
              background: 'white',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'space-around',
            }}
          >
            <div
              className="font-bold bg-white flex justify-between"
              style={{
                padding: '18px 0',
              }}
            >
              <div
                className="flex"
                style={{
                  marginBottom: 18,
                  fontSize: '0.75rem',
                  alignItems: 'center',
                }}
              >
                Views
                <div
                  role="button"
                  className={classes.upload}
                  style={{
                    marginRight: '1rem',
                  }}
                  onClick={() =>
                    setIsTitles({
                      canvas: refVisits.current.chartInstance.canvas,
                      chart: charts.VIEWS,
                    })
                  }
                >
                  <img src="/static/icons/upload.svg" alt="Upload" />
                </div>
                <div>
                  <TimeStep
                    onClick={changeTimestampHandler}
                    // setCurrentStep={setCurrentStepVisits}
                    visitsDateValue={visitsDateValue}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem',
                }}
              />
              <MultiDateRangePicker />
            </div>
            <div
              className={`w-full relative flex flex-col ${classes.h12}`}
              ref={scrollRef}
              style={{
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '98%',
                  maxWidth:
                    filteredVisitStats.length < 3 ? 'calc(100% - 5px)' : 'none',
                  minWidth: filteredVisitStats.length * size(16),
                  overflowY: 'overlay',
                }}
              >
                <Line
                  ref={refVisits}
                  data={{
                    datasets: [
                      {
                        backgroundColor: gradient,
                        lineTension: 0.01,
                        borderColor: '#36A6FD',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        pointRadius: size(15),
                        pointHoverRadius: size(15),
                        borderWidth: 1,
                        data: filteredVisitStats.map(item => item.eventsCount),
                        label: '',
                      },
                    ],
                    labels: filteredVisitStats.map(item =>
                      moment(item.eventDate).format('MM.DD'),
                    ),
                  }}
                  options={{
                    animation: false,
                    cornerRadius: size(20),
                    layout: {
                      padding: {
                        right: size(13),
                      },
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,
                    devicePixelRatio: window.devicePixelRatio * 2,

                    onHover(e, [chart]) {
                      if (!chart) {
                        dispatch(
                          updateVisitsTooltipInfo({
                            show: false,
                            showDashes: false,
                            showLabel: false,
                            showCircle: false,
                          }),
                        );
                        return;
                      }

                      const value = filteredVisitStats[chart._index];

                      dispatch(
                        setVisitsTooltipInfo({
                          showCircle: true,
                          showDashes: true,
                          type: TOOLTIP_TYPE.SMALL,
                          show: true,
                          pointId: value.pointId,
                          label: chart._xScale.ticks[chart._index],
                          smallTooltipText: `${value.eventsCount}`,
                          dashesHeight: `${
                            refVisits.current.chartInstance.height - 20
                          }px`,
                          showLabel: true,
                          tooltipLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            40,
                          tooltipTop: chart._view.y + 45,
                          circleLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            30,
                          circleTop: chart._view.y + 65,
                          dashesLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            40,
                          dashesTop:
                            e.target.parentElement.parentElement.offsetTop,
                          labelLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            40,
                          labelBottom: 15,
                        }),
                      );
                    },
                    tooltips: {
                      enabled: false,
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: size(8),
                            reverse: true,
                          },
                          categoryPercentage: 0.97,
                          barPercentage: 0.97,
                          barThickness: 28,
                          scaleLabel: {
                            display: true,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontStyle: 'bold',
                            fontColor: '#000',
                            stepSize: 250,
                            beginAtZero: true,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: 'Views',
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </div>
            <GraphTooltip store="visitsTooltip" />
            <ViewDurationChart />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default OverviewView;
