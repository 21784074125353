import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from 'react-loading-skeleton';

import clsx from 'clsx';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip,
  Collapse,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PlayCircleOutlineRounded from '@material-ui/icons/PlayCircleOutlineRounded';
import { isEmpty } from 'lodash';

import { authRoles } from '~/constants';
import { getCompaniesFullStats, buildLocationsForUser } from '~/services/api';
import {
  getSubscriptionById,
  getCustomerEmailById,
} from '~/services/stripeApi';

import StripeDialog from '~/views/private/billings/components/StripeDialog';
import QuickBooksStatusChip from '~/views/private/billings/components/QuickBooksStatusChip';
import { sortByFieldName, timeConverter, qBTimeConverter } from '../utils';
import useFetchQuickBooksData from '~/hooks/useFetchQuickBooksData';

const useStyles = makeStyles(() => ({
  subTitle: {
    fontWeight: 200,
  },
  tableHeaderCell: {
    textAlign: 'center',
    width: '25rem',
    fontWeight: 700,
    fontSize: 'larger',
    background: 'rgba(54, 166, 253, 0.15)',
  },
  tableHeaderArrowCell: {
    textAlign: 'center',
    width: '5rem',
    background: 'rgba(54, 166, 253, 0.15)',
  },
  tableArrowCell: {
    textAlign: 'center',
    width: '5rem',
  },
  tableCell: {
    textAlign: 'center',
    width: '25rem',
  },
  tableCellComment: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '10rem',
    whiteSpace: 'nowrap',
  },
  multilocationName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '20rem',
    whiteSpace: 'nowrap',
  },
  tableCommentCell: {
    borderLeft: '3px solid #363636',
  },
  tableSubRowHeaderLocation: {
    fontWeight: 700,
    paddingLeft: '7rem',
  },
  tableSubRowLocation: {
    paddingLeft: '8rem',
    textAlign: 'left',
  },
  tableSubRowHeaderPrice: {
    fontWeight: 700,
    paddingLeft: '7rem',
    textAlign: 'center',
  },
  tableSubRowPrice: {
    paddingLeft: '7rem',
    textAlign: 'center',
  },
  tablePropertyCell: {
    fontWeight: 500,
    fontSize: 'large',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '26rem',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  loadingStyle: {
    height: '78vh',
    width: '94vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  statusCell: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
  },
  lastCell: {
    borderBottom: 'none',
  },
  statusImg: {
    width: '1rem',
    marginTop: '6px',
    marginLeft: '0.5rem',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f2f2f2',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(204, 204, 220, 0.8)',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)',
    fontSize: 13,
    textAlign: 'center',
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  skeletonContent: {
    textAlign: 'center',
    fontSize: '2rem',
    color: 'grey',
    margin: '20px',
  },
  tourNumber: {
    display: 'inline',
    paddingRight: '1rem',
    fontWeight: '400',
  },
  startTourButtonGreen: {
    color: '#5FCC9C',
  },
  startTourButtonBlue: {
    color: '#36A6FD',
  },
  logoImg: {
    width: '22px',
    marginLeft: '12px',
    borderRadius: '50%',
  },
}));

const StripesTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const isAdmin = user.role === authRoles.ADMIN;

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openedItemId, setOpenedItemId] = useState(false);

  const { qbData, loading } = useFetchQuickBooksData();

  const MONTHLY_PRICE_PER_LOCATION = 4;
  const ANNUALLY_PRICE_PER_LOCATION = 48;

  const toggleOpenItem = (event, id) => {
    event.stopPropagation();

    if (openedItemId === id) {
      setOpenedItemId(null);
    } else {
      setOpenedItemId(id);
    }
  };

  const locations = useAsync(async () => {
    return isAdmin
      ? await getCompaniesFullStats()
      : await buildLocationsForUser(user.company);
  }, [isAdmin]);

  const multiLocations = useMemo(() => locations.value || [], [locations]);
  const filteredByCompanyName = useMemo(
    () => multiLocations.sort(sortByFieldName('companyName')),
    [multiLocations],
  );

  const handleStateChangeInPaymentDialog = () => {
    setRefresh(prevState => !prevState);
  };

  const getCustomerData = async tourData => {
    const { subscription_id, qb_recurring_transaction_id } =
      tourData.locations[0];
    const subscription = subscription_id
      ? await getSubscriptionById(subscription_id)
      : {};
    const { customer } = subscription;

    return {
      ...tourData,
      subscription,
      qb_recurring_transaction_id,
      customer_email: customer
        ? await getCustomerEmailById(customer)
        : qb_recurring_transaction_id
        ? ''
        : '-',
    };
  };

  const getTourData = tours => {
    return Promise.all(tours.map(getCustomerData));
  };

  useEffect(() => {
    const loadTourData = async () => {
      setIsLoading(true);
      if (filteredByCompanyName.length > 0) {
        const tourData = await getTourData(filteredByCompanyName);
        setData(tourData);
      }
      setIsLoading(false);
    };

    loadTourData().then(res => res);
  }, [filteredByCompanyName, refresh]);

  const handleStartTour = e => e.stopPropagation();

  const getInvoiceById = (qbData, id) => {
    return qbData.QueryResponse.RecurringTransaction.find(
      invoice => invoice.Invoice.Id === id,
    );
  };

  if (locations.loading || isLoading) {
    return <div className={classes.loadingStyle}>Loading...</div>;
  }

  if (data.length === 0) {
    return (
      <div className={classes.skeleton}>
        <Skeleton height="100%" />
        <p className={classes.skeletonContent}>No data available</p>
      </div>
    );
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderArrowCell} />
                <TableCell className={classes.tableHeaderCell}>
                  Product
                </TableCell>
                {/* <TableCell className={classes.tableHeaderCell}> */}
                {/*   <div>Full Price</div> */}
                {/*   <div className={classes.subTitle}>Monthly / Annually</div> */}
                {/* </TableCell> */}
                <TableCell className={classes.tableHeaderCell}>
                  Customer Email
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Start Date
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Next Billing Date
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Invoice Price
                </TableCell>
                {isAdmin && (
                  <TableCell className={classes.tableHeaderCell}>
                    Comments
                  </TableCell>
                )}
                <TableCell className={classes.tableHeaderCell}>
                  <div className={classes.statusCell}>
                    <div>Status</div>
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={'Click on status button for subscription managing'}
                    >
                      <img
                        className={classes.statusImg}
                        src={`/static/icons/unpaid.svg`}
                        alt={'Info'}
                      />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((tour, index) => (
                <React.Fragment key={tour.id}>
                  <TableRow
                    hover
                    onClick={event => toggleOpenItem(event, tour.id)}
                  >
                    <TableCell className={classes.tableArrowCell}>
                      {openedItemId === tour.id ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowRightIcon />
                      )}
                    </TableCell>
                    <TableCell className={classes.tablePropertyCell}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={classes.tourNumber}>
                            {`${index + 1}.`}
                          </div>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={tour.multilocationName}
                          >
                            <div className={classes.multilocationName}>
                              {tour.multilocationName}
                            </div>
                          </Tooltip>
                          {(tour.locations[0].qb_recurring_transaction_id ||
                            tour.locations[0].subscription_id) && (
                            <img
                              className={classes.logoImg}
                              src={`/static/icons/${
                                tour.locations[0].qb_recurring_transaction_id
                                  ? 'quickbooks'
                                  : 'stripe'
                              }-logo.svg`}
                              alt={'Logo'}
                            />
                          )}
                        </div>
                        <a
                          href={`${process.env.PLAYER_URL}?locationId=${tour.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleStartTour}
                        >
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={'Start tour'}
                          >
                            <PlayCircleOutlineRounded
                              fontSize="medium"
                              className={
                                tour.locations[0].is_photography_tour
                                  ? classes.startTourButtonGreen
                                  : classes.startTourButtonBlue
                              }
                            />
                          </Tooltip>
                        </a>
                      </Box>
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}> */}
                    {/*   {`$${ */}
                    {/*     tour.locations.length * MONTHLY_PRICE_PER_LOCATION */}
                    {/*   } / $${ */}
                    {/*     tour.locations.length * ANNUALLY_PRICE_PER_LOCATION */}
                    {/*   }`} */}
                    {/* </TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {tour.qb_recurring_transaction_id &&
                        loading &&
                        isEmpty(tour.subscription) && <Skeleton height={10} />}
                      {tour.qb_recurring_transaction_id && !loading
                        ? getInvoiceById(
                            qbData,
                            tour.qb_recurring_transaction_id,
                          ).Invoice.BillEmail.Address
                        : tour.customer_email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tour.qb_recurring_transaction_id &&
                        loading &&
                        isEmpty(tour.subscription) && <Skeleton height={10} />}
                      {tour.qb_recurring_transaction_id &&
                        !loading &&
                        qBTimeConverter(
                          getInvoiceById(
                            qbData,
                            tour.qb_recurring_transaction_id,
                          ).Invoice.RecurringInfo.ScheduleInfo.StartDate,
                        )}
                      {tour.subscription.current_period_start &&
                        timeConverter(tour.subscription.current_period_start)}
                      {!tour.qb_recurring_transaction_id &&
                        isEmpty(tour.subscription) &&
                        '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tour.qb_recurring_transaction_id &&
                        loading &&
                        isEmpty(tour.subscription) && <Skeleton height={10} />}
                      {tour.qb_recurring_transaction_id &&
                        !loading &&
                        qBTimeConverter(
                          getInvoiceById(
                            qbData,
                            tour.qb_recurring_transaction_id,
                          ).Invoice.RecurringInfo.ScheduleInfo.NextDate,
                        )}
                      {tour.subscription.current_period_end &&
                        timeConverter(tour.subscription.current_period_end)}
                      {!tour.qb_recurring_transaction_id &&
                        isEmpty(tour.subscription) &&
                        '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tour.qb_recurring_transaction_id &&
                        loading &&
                        isEmpty(tour.subscription) && <Skeleton height={10} />}
                      {tour.qb_recurring_transaction_id &&
                        !loading &&
                        `$${
                          getInvoiceById(
                            qbData,
                            tour.qb_recurring_transaction_id,
                          ).Invoice.TotalAmt
                        }`}
                      {!isEmpty(tour.subscription) &&
                        `$${
                          (tour.subscription.plan.amount *
                            tour.subscription.quantity) /
                          100
                        }`}
                      {!tour.qb_recurring_transaction_id &&
                        isEmpty(tour.subscription) &&
                        '-'}
                    </TableCell>
                    {isAdmin && (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={
                          tour.qb_recurring_transaction_id &&
                          !loading &&
                          getInvoiceById(
                            qbData,
                            tour.qb_recurring_transaction_id,
                          ).Invoice.PrivateNote
                            ? `${
                                getInvoiceById(
                                  qbData,
                                  tour.qb_recurring_transaction_id,
                                ).Invoice.PrivateNote
                              }`
                            : !isEmpty(tour.subscription.metadata) &&
                              tour.subscription.metadata.comment
                            ? tour.subscription.metadata.comment
                            : ''
                        }
                      >
                        <TableCell
                          className={`${classes.tableCell} ${classes.tableCellComment}`}
                        >
                          {tour.subscription.metadata &&
                            tour.subscription.metadata.comment &&
                            `${tour.subscription.metadata.comment}`}
                          {tour.qb_recurring_transaction_id && loading && (
                            <Skeleton height={10} />
                          )}
                          {tour.qb_recurring_transaction_id &&
                            !loading &&
                            getInvoiceById(
                              qbData,
                              tour.qb_recurring_transaction_id,
                            ).Invoice.PrivateNote &&
                            `${
                              getInvoiceById(
                                qbData,
                                tour.qb_recurring_transaction_id,
                              ).Invoice.PrivateNote
                            }`}
                          {(!tour.qb_recurring_transaction_id ||
                            (tour.qb_recurring_transaction_id &&
                              !loading &&
                              !getInvoiceById(
                                qbData,
                                tour.qb_recurring_transaction_id,
                              ).Invoice.PrivateNote)) &&
                            (isEmpty(tour.subscription.metadata) ||
                              !tour.subscription.metadata.comment) &&
                            '-'}
                        </TableCell>
                      </Tooltip>
                    )}
                    <TableCell className={classes.tableCell}>
                      {tour.qb_recurring_transaction_id && loading && (
                        <Skeleton height={10} />
                      )}
                      {tour.qb_recurring_transaction_id && !loading ? (
                        <QuickBooksStatusChip
                          isAdmin={isAdmin}
                          qbRecurringTransactionId={
                            getInvoiceById(
                              qbData,
                              tour.qb_recurring_transaction_id,
                            ).Invoice.Id
                          }
                          invoiceStatus={
                            getInvoiceById(
                              qbData,
                              tour.qb_recurring_transaction_id,
                            ).Invoice.Balance === 0
                              ? 'active'
                              : new Date(
                                  getInvoiceById(
                                    qbData,
                                    tour.qb_recurring_transaction_id,
                                  ).Invoice.RecurringInfo.ScheduleInfo.NextDate,
                                )
                                  .toISOString()
                                  .split('T')[0] <
                                new Date().toISOString().split('T')[0]
                              ? 'past_due'
                              : 'pending'
                          }
                          onClick={e => e.stopPropagation()}
                        />
                      ) : (
                        !tour.qb_recurring_transaction_id && (
                          <StripeDialog
                            subsStatus={tour.subscription.status}
                            subsId={tour.subscription.id}
                            customerId={tour.subscription.customer}
                            onStateChange={handleStateChangeInPaymentDialog}
                            isActive={tour.subscription.status === 'active'}
                            isPaused={
                              tour.subscription.pause_collection &&
                              tour.subscription.pause_collection.resumes_at ===
                                null
                            }
                            isCanceled={tour.subscription.status === 'canceled'}
                            onClick={e => e.stopPropagation()}
                          />
                        )
                      )}
                      {tour.subscription.status === 'canceled' && (
                        <div className={classes.subTitle}>
                          (subscription was active until{' '}
                          {timeConverter(tour.subscription.current_period_end)})
                        </div>
                      )}
                      {/* {tour.subscription.status === 'past_due' && ( */}
                      {/*   <div className={classes.subTitle}> */}
                      {/*     (your paid period ended on{' '} */}
                      {/*     {timeConverter(tour.subscription.current_period_end)} */}
                      {/*     ) */}
                      {/*   </div> */}
                      {/* )} */}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={isAdmin ? 9 : 8}
                    >
                      <Collapse
                        in={openedItemId === tour.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={classes.tableSubRowHeaderLocation}
                                >
                                  Location
                                </TableCell>
                                {/* <TableCell */}
                                {/*   className={classes.tableSubRowHeaderPrice} */}
                                {/* > */}
                                {/*   Monthly price */}
                                {/* </TableCell> */}
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                {isAdmin && <TableCell />}
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tour.locations.length > 0 &&
                                tour.locations.map((location, index) => (
                                  <TableRow
                                    hover
                                    key={location.id}
                                    className={classes.tableRow}
                                  >
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      } ${classes.tableSubRowLocation}`}
                                    >
                                      {location.name}
                                    </TableCell>
                                    {/* <TableCell */}
                                    {/*   className={`${classes.tableCell} ${ */}
                                    {/*     index === tour.locations.length - 1 */}
                                    {/*       ? classes.lastCell */}
                                    {/*       : '' */}
                                    {/*   } ${classes.tableSubRowPrice}`} */}
                                    {/* > */}
                                    {/*   {`$${MONTHLY_PRICE_PER_LOCATION}`} */}
                                    {/* </TableCell> */}
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    {isAdmin && (
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index === tour.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                    )}
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </Card>
  );
};

StripesTable.propTypes = {
  className: PropTypes.string,
};

StripesTable.defaultProps = {
  className: '',
};

export default StripesTable;
