import { createSlice } from '@reduxjs/toolkit';
import { TOOLTIP_INITIAL } from '~/views/private/overview/constants';

const visitsTooltip = createSlice({
  name: 'visitsTooltip',
  initialState: TOOLTIP_INITIAL,
  reducers: {
    setVisitsTooltipInfo: (state, action) => {
      return action.payload;
    },
    updateVisitsTooltipInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
    hideVisitsTooltip: (state, action) => {
      if (state.cursorInside && !action.payload.force) {
        return state;
      }

      return {
        show: false,
        showDashes: false,
        showLabel: false,
        showCircle: false,
      };
    },
  },
});

export const {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
  hideVisitsTooltip,
} = visitsTooltip.actions;

export default visitsTooltip.reducer;
