import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { makeStyles, Tooltip } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  box: {
    width: '320px',
    [theme.breakpoints.up('hiRes')]: {
      width: '17.5vw',
    },
  },
  foldButton: {
    color: 'black',
    position: 'absolute',
    right: -30,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 30,
    outline: 'none!important',
    border: 'none',
    background: 'white',
    padding: 2,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  searchInput: {
    width: '100%',
    height: '29px',
    color: '#333',
    fontSize: '0.875rem',
    fontWeight: 400,
    padding: '5px 30px 5px 40px',
    outline: 'none',
    backgroundColor: '#F6F5F5',
    backgroundImage: 'url(/static/icons/search.svg)',
    backgroundPosition: '13px 7px',
    backgroundRepeat: 'no-repeat',
    border: '1px solid transparent',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
      height: '2rem',
      padding: '0.3rem 0px 0.3rem 2.6rem',
      backgroundPosition: '0.6rem 0.5rem',
      backgroundSize: '1rem',
    },

    '&:focus': {
      backgroundImage: 'url(/static/icons/search-active.svg)',
      border: '1px solid #36A6FD',
    },
    '&:hover': {
      backgroundImage: 'url(/static/icons/search-active.svg)',
      border: '1px solid #36A6FD',
    },
  },
  searchBox: {
    width: '100%',
    display: 'flex',
  },
  searchClean: {
    width: '10px',
    height: '10px',
    backgroundImage: 'url(/static/icons/cross.svg)',
    margin: '10px 0 0 -22px',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.7em',
      height: '0.7em',
      backgroundSize: 'cover',
      margin: '0.7em 0 0 -1.5em',
    },

    '&:hover': {
      backgroundImage: 'url(/static/icons/cross-hover.svg)',
    },
    '&:focus': {
      outline: 'none',
    },
  },

  recently: {
    fontSize: '12px',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },

    '&:hover': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },
  },

  view: {
    '& .Mui-expanded > .MuiTreeItem-content': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },
  },

  item: {
    margin: '2px 0',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: '#333',
    fontSize: '0.75rem',
    fontWeight: 400,
    '&:hover, &.active': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
    padding: '2px 4px',
    wordBreak: 'break-all',

    // '&:has( >a.active)': {background: 'rgba(54, 166, 253, 0.15) !important'},

    '& .icon[data-visible="false"]': { display: 'none' },

    '& .icon': {
      width: '10px',
      height: '10px',
      backgroundImage: 'url(/static/icons/open.svg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      marginLeft: '20px',

      [theme.breakpoints.up('hiRes')]: {
        width: '0.625rem',
        height: '0.625rem',
      },
    },

    '&:hover .icon': {
      backgroundImage: 'url(/static/icons/open-active.svg)',
    },
  },

  hr: {
    width: '100%',
    height: '1px',
    marginBottom: '30px',
    background: 'rgba(229, 229, 229, 0.5)',

    [theme.breakpoints.up('hiRes')]: {
      marginBottom: '0.83rem',
    },
  },
  treeItem: {
    '& .MuiTreeItem-label': { padding: '0 !important' },
    '& .MuiTreeItem-iconContainer': { display: 'none !important' },
    '& .MuiTreeItem-label:hover': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },

    '&.Mui-expanded > .MuiTreeItem-content .icon': {
      transform: 'rotate(90deg) !important',
    },
    // '& .Mui-selected .MuiTreeItem-label': {background: 'rgba(54, 166, 253, 0.15) !important'},

    // '&[aria-expanded="true"] .icon': {
    //   backgroundImage: 'url(/static/icons/close.svg) !important',
    // },
  },
  expandable: {
    '&.Mui-expanded > .MuiTreeItem-content .icon': {
      transform: 'rotate(90deg) !important',
    },
  },

  treeItemLocation: {
    display: 'block',
    width: '100%',
    // background: 'transparent !important',
    padding: '2px 4px',
    fontSize: '0.75rem',
    fontWeight: 400,

    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&.active': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },

  active: {
    background: 'rgba(54, 166, 253, 0.15)',
  },

  treeItemLocationActiveSearch: {
    color: '#00cf00',
  },

  boxOpen: {
    transition: '1s',
    position: 'relative',
  },

  boxClose: {
    position: 'relative',
    transition: '1s',
    marginLeft: -320,

    [theme.breakpoints.up('hiRes')]: {
      marginLeft: '-17.5vw',
    },
  },

  recViImg: {
    width: '16px',
    height: '16px',
    marginRight: '30px',
    marginLeft: '5px',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.65rem',
      height: '0.65rem',
      marginRight: '2rem',
    },
  },

  companyImg: {
    width: '16px',
    height: '16px',
    marginRight: '10px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1rem',
      height: '1rem',
    },
  },

  recentlyBox: {
    width: '100%',
    color: '#000',
    margin: '12px 0 6px 0',

    [theme.breakpoints.up('hiRes')]: {
      margin: '0.75rem 0px 0.375rem',
    },

    '& .Mui-expanded > .MuiTreeItem-content': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },
  },

  recentlyItem: {
    fontSize: '0.75rem',
    margin: '2px 0 0 50px',

    [theme.breakpoints.up('hiRes')]: {
      margin: '3px 0 0 3.125rem',
    },
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    margin: 10,
  },
}));

let nodeId = 0;

const SortBarCompany = ({
  companyName,
  multilocations,
  classes,
  searchResult,
  index,
  searchText,
}) => {
  const tooltipClass = useTooltipStyles();

  return (
    <TreeItem
      className={classes.treeItem}
      nodeId={index.toString()}
      label={
        <div className={classes.item}>
          <div style={{ display: 'flex' }}>
            <img
              alt=""
              src="/static/icons/company-folder.svg"
              className={classes.companyImg}
            />
            <div
              style={{
                fontWeight: 700,
                fontSize: '0.875rem',
                letterSpacing: '0.13em',
                textTransform: 'uppercase',
              }}
            >
              {companyName}
            </div>
          </div>

          <div className="icon" data-visible={multilocations.length > 0} />
        </div>
      }
    >
      {multilocations
        .filter(({ multilocationName }) =>
          searchResult.multilocations.has(multilocationName),
        )
        .map(({ multilocationName, locations }, _index) => {
          return (
            <TreeItem
              style={{ paddingRight: 4 }}
              key={multilocationName + index.toString()}
              nodeId={`${index}.${_index}`}
              className={classes.expandable}
              label={
                <div className={classes.item}>
                  <Tooltip
                    classes={tooltipClass}
                    placement="top"
                    title={
                      multilocationName &&
                      multilocationName !== locations[0].name
                        ? multilocationName
                        : locations[0].name
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        alt=""
                        style={{
                          width: '1rem',
                          height: '1rem',
                          marginRight: '0.625rem',
                        }}
                        src="/static/icons/folder.svg"
                      />

                      {multilocationName &&
                      multilocationName !== locations[0].name ? (
                        <NavLink
                          to={`/overview/multi/${encodeURIComponent(
                            companyName,
                          )}/${encodeURIComponent(multilocationName)}`}
                          style={{
                            fontWeight: 700,
                            fontSize: '0.875rem',
                            letterSpacing: '0.13em',
                            textTransform: 'uppercase',
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          className={classes.ellipsis}
                        >
                          {multilocationName}
                        </NavLink>
                      ) : (
                        <NavLink
                          style={{
                            fontWeight: 700,
                            fontSize: '0.875rem',
                            letterSpacing: '0.13em',
                            textTransform: 'uppercase',
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          to={`/overview/location/${locations[0].id}`}
                          className={classes.ellipsis}
                        >
                          {locations[0].name}
                        </NavLink>
                      )}
                    </div>
                  </Tooltip>

                  <div
                    className="icon"
                    data-visible={Boolean(multilocationName)}
                  />
                </div>
              }
            >
              {locations.length > 0 &&
                locations.map((location, index) => {
                  return (
                    <Tooltip
                      key={index}
                      classes={tooltipClass}
                      placement="top"
                      title={location.name}
                    >
                      <TreeItem
                        key={`${location.name} ${location.id}`}
                        nodeId={String(++nodeId)}
                        label={
                          <div className={classes.item}>
                            <NavLink
                              to={`/overview/location/${location.id}`}
                              className={cn({
                                [classes.treeItemLocation]: true,
                                [classes.treeItemLocationActiveSearch]:
                                  !!searchText &&
                                  searchResult.locations.has(location.name),
                              })}
                            >
                              {location.name}
                            </NavLink>
                          </div>
                        }
                      />
                    </Tooltip>
                  );
                })}
            </TreeItem>
          );
        })}
    </TreeItem>
  );
};

export default SortBarCompany;
