import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  // Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  row: {
    cursor: 'pointer',
  },
  table: {
    whiteSpace: 'nowrap',
  },
  nameCell: {
    width: '100%',
  },
}));

function LocationTable({ className, locations, ...rest }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  // const [selectedLocationIds, setSelectedLocationIds] = useState([]);

  // const handleSelectAll = event => {
  //   if (event.target.checked) {
  //     setSelectedLocationIds(locations.map(location => location.id));
  //   } else {
  //     setSelectedLocationIds([]);
  //   }
  // };

  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedLocationIds.indexOf(id);

  //   if (selectedIndex === -1) {
  //     setSelectedLocationIds(
  //       newSelectedLocationIds.concat(selectedLocationIds, id),
  //     );
  //   } else if (selectedIndex === 0) {
  //     setSelectedLocationIds(
  //       newSelectedLocationIds.concat(selectedLocationIds.slice(1)),
  //     );
  //   } else if (selectedIndex === selectedLocationIds.length - 1) {
  //     setSelectedLocationIds(
  //       newSelectedLocationIds.concat(selectedLocationIds.slice(0, -1)),
  //     );
  //   } else if (selectedIndex > 0) {
  //     setSelectedLocationIds(
  //       newSelectedLocationIds.concat(
  //         selectedLocationIds.slice(0, selectedIndex),
  //         selectedLocationIds.slice(selectedIndex + 1),
  //       ),
  //     );
  //   }
  // };

  const handleLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const locationsFrom = page * limit;
  const locationsTo = locationsFrom + limit;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedLocationIds.length === locations.length}
                    color="primary"
                    indeterminate={
                      selectedLocationIds.length > 0 &&
                      selectedLocationIds.length < locations.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell>ID</TableCell>
                <TableCell className={classes.nameCell}>Name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Photo?</TableCell>
                <TableCell>Multitour</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.slice(locationsFrom, locationsTo).map(location => (
                <TableRow
                  hover
                  key={location.id}
                  onClick={() => navigate(`/locations/${location.id}`)}
                  className={classes.row}
                >
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {location.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {location.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{location.company_name}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={location.description.INSTANT_PANO_ONLY}
                      // onChange={event => handleSelectOne(event, location.id)}
                      // value="true"
                      disabled
                    />
                  </TableCell>
                  <TableCell>{location.description.LIST_ID}</TableCell>
                  {/* <TableCell> */}
                  {/*   {moment(location.createdAt).format('DD/MM/YYYY')} */}
                  {/* </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={locations.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

LocationTable.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array.isRequired,
};

LocationTable.defaultProps = {
  className: '',
};

export default LocationTable;
