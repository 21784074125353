import { MagicTokenSingleton } from './token';

const BASE_URL = process.env.CLIENT_API_URL || 'http://localhost:3000/api';

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export async function tryFetch(baseUrl, route, options) {
  const res = await fetch(baseUrl + route, options);
  const body = await res.json();
  if (!res.ok) {
    throw new Error(body.message);
  }
  return body;
}

export async function login(token) {
  const req = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...jsonHeaders,
    },
    body: JSON.stringify({}),
  };

  try {
    const data = await tryFetch(BASE_URL, '/auth/login', req);
    return data;
  } catch (err) {
    console.error('Failed to log in', err);
    return null;
  }
}

export const RecentlyViewedType = Object.freeze({
  LOCATION: 'location',
  TOUR: 'tour',
});

export function getRecentlyViewed() {
  return tryFetch(BASE_URL, '/recently-viewed', {
    method: 'GET',
    headers: { Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}` },
  });
}

export function saveRecentlyViewed({ type, locationId, listId }) {
  return tryFetch(BASE_URL, '/recently-viewed', {
    method: 'POST',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
    body: JSON.stringify({ type, locationId, listId }),
  });
}
