/* eslint-disable prettier/prettier */

export const RANGE_TYPE = Object.freeze({
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
});

export const TIME_STEP = [
  {
    name: RANGE_TYPE.DAY,
    isSelected: true,
    visible: true,
  },
  {
    name: RANGE_TYPE.WEEK,
    isSelected: false,
    visible: true,
  },
  {
    name: RANGE_TYPE.MONTH,
    isSelected: false,
    visible: true,
  },
];

export const GRAPH_TYPE = Object.freeze({
  LOCATION: 'location',
  POINTS: 'locationPoints',
});

export const TOOLTIP_TYPE = Object.freeze({
  SMALL: 'SMALL',
  BIG: 'BIG',
});

export const TOOLTIP_INITIAL = {
  show: false,
  value: {},
  locationId: -1,
  locationName: '',
  bigTooltipText: '',
  smallTooltipText: '',
  showDashes: false,
  dashesHeight: -1,
  showLabel: false,
  label: '',
  showCircle: false,
  tooltipLeft: 0,
  tooltipTop: 0,
  circleLeft: 0,
  circleTop: 0,
  dashesLeft: 0,
  labelLeft: 0,
  cursorInside: false,
};

export const DEFAULT_LOCATION_STATS = {
  totalVisitsMobile: 0,
  totalVisits: 0,
  totalCustomButtonClicks: 0,
  avgVisitsPerDay: 0,
  avgVisitsPerMonth: 0,
  totalDuration: 0,
  avgVisitDuration: 0,
};

export const DEFAULT_MULTILOCATION_STATS = {
  totalVisits: 0,
  totalVisitsMobile: 0,
  last30DaysVisits: 0,
  last30DaysVisitsMobile: 0,
  totalDuration: 0,
  totalCustomButtonClicks: 0,
  totalHotspotButtonClicks: 0,
};
