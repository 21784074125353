import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import {
  Container,
  Grid,
  Box,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getLocation } from '~/services/api';
import Page from '~/components/shared/Page';
import Analytics from './components/Analytics';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  stats: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}));

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useAsync(() => getLocation(id), []);

  if (location.loading) {
    return null;
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            className={classes.title}
            color="textPrimary"
            variant="h4"
          >
            {location.value.name}
          </Typography>
        </Box>
        <Grid className={classes.stats} container spacing={3}>
          {/*   <Grid item xs={12}> */}
          {/*     <Breadcrumbs aria-label="breadcrumb"> */}
          {/*       <Link as={RouterLink} color="inherit" to="/" href="/"> */}
          {/*         Locations */}
          {/*       </Link> */}
          {/*       <Typography color="textPrimary">Breadcrumb</Typography> */}
          {/*     </Breadcrumbs> */}
          {/*   </Grid> */}
          {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid> */}
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Analytics location={location.value} />
          </Grid>
          {/* <Grid item lg={4} md={6} xl={3} xs={12}>
            <TrafficByDevice />
          </Grid> */}
          {/* <Grid item lg={4} md={6} xl={3} xs={12}> */}
          {/*   <LatestCompanies /> */}
          {/* </Grid> */}
          {/* <Grid item lg={8} md={12} xl={9} xs={12}> */}
          {/*   <LatestLocations /> */}
          {/* </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
