import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';

import * as yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import * as auth from '~/store/auth';
import { authRoles } from '~/constants';
import Page from '~/components/shared/Page';
import {
  loginWithEmail,
  loginWithSession,
  loginWithGoogle,
  magic,
} from '~/services/magic';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  },

  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  shadowBox: {
    width: '450px',
    padding: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
  },

  logo: {
    width: '170px',
    height: '90px',
    marginBottom: '30px',
  },

  header: {
    fontFamily: 'Montserrat, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    color: '#343a40',
    margin: '0',
  },

  form: {
    fontFamily: 'Montserrat, sans-serif !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: '20px',
  },

  input: {
    display: 'block',
    width: '340px',
    border: '0',
    backgroundColor: '#f8f9fa',
    transition: 'all 0.2s ease',
    fontSize: '18px',
    marginTop: '20px',
  },

  continueButton: {
    width: '340px',
    height: '52px',
    marginTop: '20px',
    backgroundColor: '#343a40',
    border: '1px solid #343a40',
    color: '#f8f9fa',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontSize: '16px',
    borderRadius: '10px',
    textTransform: 'none',

    '&:focus': {
      outline: '0',
    },

    '&:hover': {
      color: '#343a40',
      backgroundColor: '#f8f9fa',
    },
  },

  orSeparator: {
    width: '340px',
    borderBottom: '1px solid #000000',
    lineHeight: '0.1em',
    margin: '36px 0 36px',
    textAlign: 'center',
  },

  orText: {
    background: '#ffffff',
    padding: '0 20px',
    fontFamily: 'Montserrat, sans-serif !important',
    fontSize: '16px',
    fontStyle: 'normal',
  },

  googleButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px',
  },

  googleLogInButton: {
    width: '340px',
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#f8f9fa',
    backgroundColor: '#007bff',
    border: '1px solid #007bff',
    borderRadius: '10px',
    padding: '10px 20px',
    fontFamily: 'Montserrat, sans-serif !important',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    textTransform: 'none',

    '&:hover': {
      color: '#007bff',
      backgroundColor: '#f8f9fa',
    },
  },

  googleIcon: {
    margin: '0 15px',
  },

  loading: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

function roleToAuthRole(role) {
  return role === 'admin' || role === 'superadmin'
    ? authRoles.ADMIN
    : authRoles.USER;
}

function MagicAuth({ children }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const proceedAuth = useCallback(
    result => {
      if (result) {
        dispatch(
          auth.login({
            ...result,
            role: roleToAuthRole(result.role),
            company: result.companyName,
          }),
        );
        setIsLoggedIn(true);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    loginWithSession()
      .then(user => {
        if (user) {
          proceedAuth(user);
          return;
        }

        return loginWithGoogle();
      })
      .then(user => {
        if (user) {
          proceedAuth(user);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [proceedAuth]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/overview', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  if (loading) {
    return <Box className={classes.loading}>Loading...</Box>;
  }

  if (isLoggedIn) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const invalidate = actions =>
    actions.setFieldError('email', 'Email is incorrect');

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const result = await loginWithEmail(values.email);
      if (result) {
        proceedAuth(result);
      } else {
        invalidate(actions);
      }
    } catch (e) {
      console.error(e);
      invalidate(actions);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialLogin = async () => {
    setLoading(true);
    try {
      await magic.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI: new URL('/overview', window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box className={classes.container}>
        <Box className={classes.shadowBox}>
          <img
            className={classes.logo}
            src={'/static/images/logoBlack.jpg'}
            alt="Company logo"
          />
          <Typography variant="h2" className={classes.header}>
            Welcome back
          </Typography>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={yup.object().shape({
              email: yup.string().email('Must be a valid email').max(255),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  className={classes.input}
                  error={Boolean(errors.email)}
                  fullWidth
                  helperText={Boolean(errors.email)}
                  label="Email address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Button
                  className={classes.continueButton}
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Continue
                </Button>
              </form>
            )}
          </Formik>
          <Box className={classes.orSeparator}>
            <Box component="span" className={classes.orText}>
              OR
            </Box>
          </Box>
          <Box className={classes.googleButtonContainer}>
            <Button
              className={classes.googleLogInButton}
              onClick={handleSocialLogin}
            >
              <FcGoogle className={classes.googleIcon} size={'2rem'} />
              Continue with Google
            </Button>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

MagicAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MagicAuth;
