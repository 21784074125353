import React from 'react';
import PropTypes from 'prop-types';

const translateX = {
  left: '0',
  center: '-50%',
  right: '-100%',
};

// const useStyles = makeStyles({
//   image: {
//     height: 48,
//     width: 48,
//   },
// });

function Tooltip({ config, children }) {
  // const classes = useStyles();
  return (
    <div
      style={{
        position: 'absolute',
        color: '#FFFFFF',
        // backgroundColor: '#333333',
        top: config.top,
        left: config.left,
        pointerEvents: 'all',
        // padding: '10px',
        transform: `translate(${translateX[config.align]}, -50%)`,
        visibility: config.show ? 'visible' : 'hidden',
      }}
    >
      {children}
    </div>
  );
}

Tooltip.propTypes = {
  config: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
