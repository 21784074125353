import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import StripesTable from './components/StripesTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },

  header: {
    fontSize: '1.5rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1.33rem',
    },
  },
  header2: {
    fontSize: '0.875rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },
  },
}));

const BillingsView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Billings">
      <Header>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '26px',
          }}
        >
          <div>
            <div className={classes.header}>Subscriptions Billing Info</div>
          </div>
        </div>
      </Header>
      <StripesTable />
    </Page>
  );
};

export default BillingsView;
