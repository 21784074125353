import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

import { recentlyTours } from '~/data/tours';
import TourCard from '../components/TourCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  backIcon: {
    display: 'block',
    width: '18px',
    height: '17px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
}));

const GalleryView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Recently">
      <Header>
        <div style={{ fontSize: '1.5rem' }}>Recently viewed</div>
        <NavLink to="/gallery" className={classes.backIcon} />
      </Header>

      <Grid
        container
        spacing={4}
        style={{ paddingTop: '30px', paddingRight: '40px' }}
      >
        {recentlyTours.map(tour => (
          <Grid key={tour.id} item lg={4} md={6} xs={12}>
            <TourCard tour={tour} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default GalleryView;
