export const tours = [
  {
    id: 1,
    title: 'Зеленая гавань',
    image:
      'https://s3-alpha-sig.figma.com/img/8682/5fac/0a8d194b835bf73d45d245e0bbc147b9?Expires=1618790400&Signature=gFh2bOO2OgEIko6wkc2rvgKWgSJabo2zxhWv3-sIABlj76b9sCaLjJxRNtjQK8NNTa3kw6dCSCeZxZQkaWnGHMVkmiaBJ-xhrB6plsIXJamzegNRTsCn2KPTgY2MwmB4U6QJeKL7QDX0XO0D0hHxk1UD5~SAQPKUfodfFScHEgKQ5vaYcyvRK47958hTLRT6SrHzya98U~6yneHxNkCp97jdqNBbBSQZ8kAlK6xh~5l8NLGYmgFHo2nJR-DnJ4-KBg9FypuPGEsy6xc1Oz9P6Ud0bT0SQCtd9x4lizKCe0vCCqURE6xsZKghiayg4oRlr5DqyCq6f-iNv-cX1Sfy9g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    visits: 70,
    conversion: 40,
    locations: [
      'Обзор комплекс',
      'Подъезд',
      'Квартира Сити Хаус',
      'Квартира Сити Хаус 2',
      'Двор Сити Хаус',
      'Двор Сити Хаус 2',
      'Коттедж',
      'Таунхаус',
    ],
  },
  {
    id: 2,
    title: 'Новая боровая',
    image:
      'https://s3-alpha-sig.figma.com/img/c11c/f89b/e3865fa092a3a64d6a4c1b26717bfcfc?Expires=1618790400&Signature=BMPs0MHw1T~QEK4yBYL1DZzEGaru3nMmft26B4E5IrDT~vp-Jq0JkhBbXJccXFI4xSgDMgCri6D0IXIRk7vUO0LYHDhNoLJ-8eaimH1OP04zSnMtzX8UUAFmBjs-DBo8eKhiSX64DvZ4Ql1apCTIq1Noz3~oLesIECafW-CKXZ3sy0IbUBeiGCzTLhjoBaBqBq3rBUuj7VB3-RMzhjJsO2BjWc-dDINeclg77VHJaurNAUD1mr4bQf4JOu7VAx-hmnpDjwC3EpHFvIKFrsORZYyLfe66XNT~LsYXyKe5WajAK4aBjFQknXbhRnd34EB0Do1PQZuGwluTZFs7FVxAIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    visits: 70,
    conversion: 40,
    locations: ['Квартира Сити Хаус', 'Квартира Сити Хаус 2'],
  },
  {
    id: 3,
    title: 'Location',
    image:
      'https://s3-alpha-sig.figma.com/img/a6c8/9e85/650ea60bc33f4d89fadf73b791f4c2bb?Expires=1618790400&Signature=YFyFiAxrCRQbvkJOIswGHy6mC9gJk6N2BgDsWVZuLLO8ajSOrR3vc3a9jdjKYblYCMqpzjWHvza-XsHAjB6u544P1auPixT5jhMSjQFVyBK1aW~20AZIVOqQUwR1ZNZolcs--ceBYUd2PWQrjNewTewGAFuTAyiXQilvqHAvcFIFM0HRLcZ40ytkmD47sULhVTqS-q92rs56fuVWeunwNsCIIfr1QBC21WPQCg~Bq0p54x6rBtFEuJcV5wFuAncnUPCH2-NHx4VY68LE5YRXWfcTY44SoovOEuHLVjlOCfbmRStv61-inVEllbUi25R4~1QfoSNb14uFs~s-L3I60A__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    visits: 70,
    conversion: 40,
    locations: [],
  },
];

export const recentlyTours = tours.slice(0, 1);

export const mostPopularTours = tours.slice(0, 2);
