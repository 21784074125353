import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import LocationCard from '../components/LocationCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  cardContainer: {
    paddingBottom: '30px',
  },
  grid: {
    flexBasis: '25%',
    minWidth: 200,
  },
}));

const LocationsGalleryView = locations => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      className={`transform-gpu ${classes.cardContainer}`}
    >
      {locations.locations.map(location => (
        <Grid
          key={location.id}
          item
          lg={4}
          md={6}
          xs={12}
          className={classes.grid}
        >
          <LocationCard location={location} />
        </Grid>
      ))}
    </Grid>
  );
};

export default LocationsGalleryView;
