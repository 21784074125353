import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';

import { MagicTokenSingleton } from './token';
import { login } from './clientApi';

const createMagic = key => {
  return (
    typeof window !== 'undefined' &&
    new Magic(key, {
      extensions: [new OAuthExtension()],
    })
  );
};

export const magic = createMagic(process.env.MAGIC_API_KEY);

export async function loginWithSession() {
  const isLoggedIn = await magic.user.isLoggedIn();
  if (!isLoggedIn) {
    return false;
  }

  const token = await magic.user.getIdToken();
  const user = await login(token);

  await MagicTokenSingleton.I.init(magic, token);
  return user;
}

export async function loginWithEmail(email = '') {
  const token = await magic.auth.loginWithMagicLink({ email, showUI: true });
  const user = await login(token);

  await MagicTokenSingleton.I.init(magic, token);
  return user;
}

export async function loginWithGoogle() {
  const isLoggedIn = await magic.oauth.getRedirectResult();

  if (!isLoggedIn) {
    return false;
  }
  const token = await magic.user.getIdToken();
  const user = await login(token);

  await MagicTokenSingleton.I.init(magic, token);
  return user;
}

export async function logout() {
  await magic.user.logout();
}
