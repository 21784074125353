const BASE_URL = process.env.SNOWPACK_PUBLIC_API_ENDPOINT || '';

export async function getSubscriptionById(id) {
  const res = await fetch(
    `${BASE_URL}/subscription/getSubscriptionById?subscriptionId=${id}`,
  );
  if (!res.ok) {
    throw new Error('getSubscriptionById request error');
  }
  const { result } = await res.json();
  return result;
}

export async function getCustomerEmailById(customerId) {
  const res = await fetch(
    `${BASE_URL}/subscription/getCustomerEmailById?customerId=${customerId}`,
  );
  if (!res.ok) {
    throw new Error('getCustomerEmailById request error');
  }
  const { result } = await res.json();
  return result.email;
}

export async function pauseSubscriptionById(subscriptionId) {
  const res = await fetch(
    `${BASE_URL}/subscription/pauseSubscriptionById?subscriptionId=${subscriptionId}`,
  );
  if (!res.ok) {
    throw new Error('pauseSubscriptionById request error');
  }
}

export async function resumeSubscriptionById(subscriptionId) {
  const res = await fetch(
    `${BASE_URL}/subscription/resumeSubscriptionById?subscriptionId=${subscriptionId}`,
  );
  if (!res.ok) {
    throw new Error('resumeSubscriptionById request error');
  }
}

export async function cancelSubscriptionById(subscriptionId) {
  const res = await fetch(
    `${BASE_URL}/subscription/cancelSubscriptionById?subscriptionId=${subscriptionId}`,
  );
  if (!res.ok) {
    throw new Error('cancelSubscriptionById request error');
  }
}

export async function createCustomerPortalSession(customerId) {
  const res = await fetch(
    `${BASE_URL}/subscription/createCustomerPortalSession?customerId=${customerId}`,
  );
  if (!res.ok) {
    throw new Error('createCustomerPortalSession request error');
  }
  const { result } = await res.json();
  return result.url;
}
