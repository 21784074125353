export default function debounce(fn = () => {}, time = 1000) {
  let prevCall;
  let timer;
  return (...args) => {
    const lastCall = Date.now();
    if (prevCall && lastCall - prevCall <= time) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => fn(...args), time);
    prevCall = lastCall;
  };
}
