import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRange, setUseCustomRange } from '~/store/datePicker';
import { Button } from '@material-ui/core';
import CustomDateRangePicker from './DateRangePicker';

const MultiDateRangePicker = () => {
  const dispatch = useDispatch();

  const range = useSelector(state => state.datePicker.range);
  const initialRange = useSelector(state => state.datePicker.initialRange);

  const handleDateChange = useCallback(
    newDate => {
      dispatch(setRange(newDate));
    },
    [dispatch],
  );

  const resetHandler = useCallback(() => {
    dispatch(setRange(initialRange));
    dispatch(setUseCustomRange(false));
  }, [dispatch, initialRange]);

  const openHandler = useCallback(() => {
    dispatch(setUseCustomRange(true));
  }, [dispatch]);

  return (
    <div className="flex gap-x-4 mb-4">
      <CustomDateRangePicker
        value={range}
        onChange={handleDateChange}
        onOpen={openHandler}
      />
      <Button
        variant="outlined"
        onClick={resetHandler}
        size="small"
        style={{ height: '1.13rem' }}
      >
        Reset
      </Button>
    </div>
  );
};

export default MultiDateRangePicker;
