import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { logout } from '~/services/magic';
import { authRoles } from '~/constants';

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
  },
  headerProfile: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
  },
  role: {
    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },
  },
  mail: {
    fontWeight: 400,

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '0.66rem',
    },
  },
  profileImg: {
    width: '20px',
    height: '23px',
    margin: '0 20px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.6rem',
      height: '1.86rem',
      objectFit: 'contain',
      margin: '0 1.2rem',
    },
  },
  logoutIcon: {
    width: '16px',
    height: '9px',
    marginLeft: '10px',
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
  button: {},
}));

const Header = ({ children }) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);

  if (!user) return null;

  const role = user.role === authRoles.ADMIN ? 'Admin' : 'Client';

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
    } catch (err) {
      console.error(err);
    } finally {
      window.location.href = window.location.origin;
      setLoading(false);
    }
  };

  return (
    <header className={classes.header}>
      <div>{children}</div>

      <div className={classes.headerProfile}>
        <div style={{ textAlign: 'right' }}>
          <div className={classes.role}>{role}</div>
          <div className={classes.mail}>{user.email}</div>
        </div>
        <img
          alt="Profile"
          className={classes.profileImg}
          src="/static/icons/profile.svg"
        />
        <Button
          color="primary"
          disabled={loading}
          type="button"
          variant="contained"
          onClick={handleLogout}
          size="small"
        >
          {loading ? 'Logging out' : 'Log out'}
        </Button>
      </div>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
