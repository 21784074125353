import React from 'react';

import { Chip, Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(204, 204, 220, 0.8)',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)',
    fontSize: 13,
    textAlign: 'center',
  },
  chip: {
    border: '1px solid #808080',
    color: '#111111',
  },
}));

function QuickBooksStatusChip({
  invoiceStatus,
  qbRecurringTransactionId,
  isAdmin,
}) {
  const classes = useStyles();

  const STATUS_COLORS = {
    active: '#D1FAB3',
    pending: '#f3f20b',
    past_due: '#E1A960',
    default: '#EBEEF1',
  };

  const getChipColor = status => STATUS_COLORS[status] || STATUS_COLORS.default;
  const handleClick = e => e.stopPropagation();
  const getEnv = () =>
    process.env.NODE_ENV === 'production' ? 'app' : 'sandbox';

  return (
    <>
      {isAdmin ? (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={'Click to open recurring transaction link'}
        >
          <a
            href={`https://${getEnv()}.qbo.intuit.com/app/recurringinvoice?templateAction=GET&txnId=${qbRecurringTransactionId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Chip
              clickable
              color="primary"
              size="medium"
              style={{ backgroundColor: getChipColor(invoiceStatus) }}
              className={classes.chip}
              label={invoiceStatus === 'past_due' ? 'past due' : invoiceStatus}
              onClick={handleClick}
            />
          </a>
        </Tooltip>
      ) : (
        <Chip
          clickable
          color="primary"
          size="medium"
          style={{ backgroundColor: getChipColor(invoiceStatus) }}
          className={classes.chip}
          label={invoiceStatus}
          onClick={handleClick}
        />
      )}
    </>
  );
}

export default QuickBooksStatusChip;
