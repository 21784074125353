import moment from 'moment';

export function sortByFieldName(fieldName) {
  return (a, b) => (a[fieldName] > b[fieldName] ? 1 : -1);
}

export function timeConverter(timeStamp, format = 'MM/DD/YYYY') {
  return moment(new Date(timeStamp * 1000)).format(format);
}

export function qBTimeConverter(time, format = 'MM/DD/YYYY') {
  return moment(time).format(format);
}

export function getTimePeriod(interval) {
  switch (interval) {
    case 'day':
    case 'daily':
      return 'day';
    case 'week':
    case 'weekly':
      return 'week';
    case 'month':
    case 'monthly':
      return 'month';
    case 'year':
    case 'yearly':
      return 'year';
    default:
      throw new Error('Invalid input');
  }
}
