import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Button,
  Tooltip,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';

import {
  cancelSubscriptionById,
  pauseSubscriptionById,
  resumeSubscriptionById,
  createCustomerPortalSession,
} from '~/services/stripeApi';

const useStyles = makeStyles(() => ({
  link: {
    color: '#5844f6',
    textDecoration: 'underline',
    fontWeight: '500',
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pauseButton: {
    backgroundColor: '#FDB913',
    color: 'black',
    '&:hover': {
      backgroundColor: '#c99d0c',
    },
  },
  resumeButton: {
    backgroundColor: '#8BC34A',
    color: 'white',
    '&:hover': {
      backgroundColor: '#6e9a36',
    },
  },
  cancelButton: {
    backgroundColor: '#D32F2F',
    color: 'white',
    marginLeft: '1rem',
    '&:hover': {
      backgroundColor: '#a62626',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(204, 204, 220, 0.8)',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)',
    fontSize: 13,
    textAlign: 'center',
  },
  chip: {
    border: '1px solid #808080',
    color: '#111111',
  },
  dialogTitle: {
    color: '#0a6377',
    fontWeight: '400',
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  dialogSubTitle: {
    color: '#0a6377',
    fontWeight: '400',
    fontSize: '18px',
    textAlign: 'center',
  },
  dialogButton: {
    marginBottom: '.5rem',
    fontSize: '1rem',
  },
  cancelStatusDialogButton: {
    marginBottom: '1rem',
    fontSize: '1rem',
  },
  draggableDialogTitle: {
    cursor: 'move',
    paddingBottom: '.5rem',
  },
  firstDivider: {
    margin: '.5rem 1.5rem 1rem',
  },
  secondDivider: {
    width: '100%',
    margin: '1rem',
  },
  boxContent: {
    marginBottom: '1rem',
  },
  cancelDialogBox: {
    textAlign: 'center',
    color: '#0a6377',
    margin: '1.5rem 0 1rem 0',
    fontWeight: '400',
    fontSize: '18px',
  },
}));

const statuses = ['active', 'trialing', 'past_due'];

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function StripeDialog({
  subsStatus,
  subsId,
  customerId,
  isPaused,
  isCanceled,
  isActive,
  onStateChange,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [customerPortalSessionUrl, setCustomerPortalSessionUrl] = useState('');
  const [initialOpen, setInitialOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const STATUS_COLORS = {
    active: '#D1FAB3',
    canceled: '#F14358',
    trialing: 'rgba(207, 245, 246)',
    past_due: '#E1A960',
    default: '#EBEEF1',
  };

  const setChipColor = status => STATUS_COLORS[status] || STATUS_COLORS.default;

  const initializeCustomerPortalSession = async () => {
    setIsLoading(true);
    const url = await createCustomerPortalSession(customerId);
    setCustomerPortalSessionUrl(url);
    setIsLoading(false);
    setInitialOpen(false);
  };

  const handleClickOpen = async () => {
    setOpen(true);
    if (initialOpen) {
      try {
        await initializeCustomerPortalSession();
      } catch (error) {
        console.error('Failed to initialize customer portal session: ', error);
        setIsLoading(false);
      }
    }
  };

  const goToCustomerPortal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cancelSubscription = async () => {
    setOpen(false);
    await cancelSubscriptionById(subsId);
    onStateChange();
  };

  const resumeSubscription = async () => {
    setOpen(false);
    await resumeSubscriptionById(subsId);
    onStateChange();
  };

  const pauseSubscription = async () => {
    setOpen(false);
    await pauseSubscriptionById(subsId);
    onStateChange();
  };

  return (
    <>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={
          subsStatus && !isCanceled
            ? 'Choose subscription action'
            : isCanceled
            ? 'Once the subscription is cancelled, it cannot be reinstated'
            : ''
        }
      >
        {subsStatus && !isCanceled && isPaused ? (
          <Chip
            clickable
            color="primary"
            size="medium"
            style={{ backgroundColor: '#8c8e91' }}
            className={classes.chip}
            label={'paused'}
            onClick={event => {
              event.stopPropagation();
              isPaused && handleClickOpen();
            }}
          />
        ) : (
          <Chip
            clickable
            color="primary"
            size="medium"
            style={{ backgroundColor: setChipColor(subsStatus) }}
            className={classes.chip}
            label={
              !subsStatus
                ? 'no subscription'
                : subsStatus === 'past_due'
                ? 'past due'
                : subsStatus
            }
            onClick={event => {
              event.stopPropagation();
              subsStatus && handleClickOpen();
            }}
          />
        )}
      </Tooltip>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {isCanceled ? (
            <>
              <DialogTitle
                className={classes.draggableDialogTitle}
                id="draggable-dialog-title"
              >
                <div>
                  <Typography variant="body1" className={classes.dialogTitle}>
                    To check billing information
                  </Typography>
                </div>
              </DialogTitle>
              <Divider className={classes.firstDivider} />
              <DialogContent className={classes.dialogContent}>
                {customerPortalSessionUrl && (
                  <a
                    href={`${customerPortalSessionUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      type="button"
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={goToCustomerPortal}
                      className={classes.cancelStatusDialogButton}
                    >
                      Go To Stripe Customer Portal
                    </Button>
                  </a>
                )}
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle
                className={classes.draggableDialogTitle}
                id="draggable-dialog-title"
              >
                <div>
                  <Typography variant="body1" className={classes.dialogTitle}>
                    Cancellation is irreversible!
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.dialogSubTitle}
                  >
                    Once the subscription is cancelled, it cannot be reinstated.
                  </Typography>
                </div>
              </DialogTitle>
              <Divider className={classes.firstDivider} />
              <DialogContent className={classes.dialogContent}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {statuses.includes(subsStatus) && !isPaused && (
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={pauseSubscription}
                        className={`${classes.pauseButton} ${classes.dialogButton}`}
                      >
                        Pause Payment Collection
                      </Button>
                    )}
                    {isPaused && isActive && (
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={resumeSubscription}
                        className={`${classes.resumeButton} ${classes.dialogButton}`}
                      >
                        Resume Payment Collection
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={cancelSubscription}
                      className={`${classes.cancelButton} ${classes.dialogButton}`}
                    >
                      Cancel Subscription
                    </Button>
                  </Box>
                  <Divider className={classes.secondDivider} />
                  <Box
                    className={`${classes.dialogTitle} ${classes.boxContent}`}
                  >
                    To manage another billing action
                  </Box>
                  {customerPortalSessionUrl && (
                    <a
                      href={`${customerPortalSessionUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={goToCustomerPortal}
                        className={classes.dialogButton}
                      >
                        Go To Stripe Customer Portal
                      </Button>
                    </a>
                  )}
                  <Box component="div" className={classes.cancelDialogBox}>
                    If you have doubts or queries, please feel free to&nbsp;
                    <NavLink to="/contacts" className={classes.link}>
                      contact us
                    </NavLink>
                    .
                  </Box>
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
      )}
    </>
  );
}

export default StripeDialog;
