import { useState, useEffect } from 'react';

const useFetchQuickBooksData = () => {
  const [qbData, setQbData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuickBooksData = async () => {
      const BASE_URL = process.env.SNOWPACK_PUBLIC_API_ENDPOINT || '';
      try {
        setLoading(true);
        setError(null);
        const res = await fetch(`${BASE_URL}/quickBooks/getQuickBooksData`);
        if (!res.ok) {
          throw new Error('QuickBooks request error');
        }
        const { result } = await res.json();
        setQbData(result.data);
      } catch (err) {
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    };
    fetchQuickBooksData().then(r => r);
  }, []);

  return { qbData, loading, error };
};

export default useFetchQuickBooksData;
