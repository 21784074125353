/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import { useAsync } from 'react-use';

import moment from 'moment';
import { isEqual } from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cn from 'classnames';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { makeStyles, Tooltip } from '@material-ui/core';

import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getLocation,
  getSummaryStats,
  getLocationPointViewDurationStats,
  getLocationViewDurationStats,
  getLocationPointClicksStats,
  getLocationVisitsStats,
  getLocationMenuClickStats,
} from '~/services/api';

import { formatDateFromSeconds } from '~/utils/helper';
import {
  calculateChartSize,
  calculateEventsInIntervals,
  calculateImgSize,
  filterStatsByDate,
  formatLabels,
  renderDecimal,
} from '~/views/private/overview/utils';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';

import {
  changeVisible,
  setInitialRange,
  trySetRange,
} from '~/store/datePicker';

import {
  hideDurationTooltip,
  setDurationTooltipInfo,
  updateDurationTooltipInfo,
} from '~/store/durationTooltip';
import {
  hidePointVisitsTooltip,
  setPointsVisitTooltipInfo,
  updatePointsVisitTooltipInfo,
} from '~/store/pointsVisitsTooltip';
import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';
import { DEFAULT_LOCATION_STATS, GRAPH_TYPE, TOOLTIP_TYPE } from './constants';

import {
  MultiDateRangePicker,
  CopyInput,
  ViewsByDevices,
  TimeStep,
  InfoCard,
  TopPoint,
  GraphTooltip,
} from './Components';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '2.8em',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  viewDurationSettersWrapper: {
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 1,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    borderRadius: 6,
  },
  viewDurationSetter: {
    color: '#CBC9C9',
    cursor: 'pointer',
    fontSize: '0.75rem',
    padding: '3px 10px',
    borderRadius: 6,
    background: 'rgba(54, 166, 253, 0.05)',
    '&.active': {
      background: 'rgba(54, 166, 253, 0.15)',
      color: '#333',
    },
  },
  upload: {
    background: 'rgba(54, 166, 253, 0.15)',
    borderRadius: 6,
    padding: '3px 4px',
    width: '24px',
    display: 'flex',
    marginLeft: 6,
    height: '24px',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.4rem',
      height: '1.4rem',
      padding: '0.28rem',
    },
  },
  h12: {
    height: '370px',

    [theme.breakpoints.up('hiRes')]: {
      height: '12vw',
    },
  },
  h14: {
    height: '370px',

    [theme.breakpoints.up('hiRes')]: {
      height: '14.5vw',
    },
  },
  doughnutContainer: {
    '& > canvas': {
      margin: '0 auto',
      maxWidth: '541px',

      [theme.breakpoints.up('hiRes')]: {
        maxWidth: '25vw',
      },
    },
  },
  triangle: {
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '5px 0 5px 5px',
    borderColor: 'transparent transparent transparent #000000',

    [theme.breakpoints.up('hiRes')]: {
      borderWidth: '0.3rem 0 0.3rem 0.3rem',
    },
  },
  smallTooltip: {
    borderRadius: '3px',
    transform: 'translate(-50%, -50%)',
    width: '50px',
    height: '30px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '3.125rem',
      height: '1.875rem',
    },
  },
  bigTooltip: {
    transform: 'translate(-50%, -100%)',
    borderRadius: '3px',
    width: '187px',
    paddingBottom: '20px',
    transition: 'opacity, left 0.1s ease',
    display: 'block',
    zIndex: 10,

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '11.69rem',
      height: '9.06rem',
      paddingBottom: '1.25rem',
    },
  },
  tooltipImg: {
    height: '70px',
    width: '100%',
    objectFit: 'cover',
    position: 'relative',

    [theme.breakpoints.up('hiRes')]: {
      height: '4.375rem',
    },
  },
  smallTriangle: {
    position: 'absolute',
    top: '20px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      top: '1.25rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  verySmallTriangle: {
    position: 'absolute',
    top: '-6px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #363636',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '-0.375rem',
      borderLeft: '0.625rem solid transparent',
      borderRight: '0.625rem solid transparent',
      borderBottom: '0.625rem solid #363636',
    },
  },
  bigTriangle: {
    position: 'absolute',
    bottom: '5px',
    /* top: '120px', */
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '7.5rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  bigTooltipPaper: {
    padding: '5px 5px 15px',
    pointerEvents: 'none',
    background: '#36A6FD',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.3rem 0.3rem 1rem',
    },
  },
  activeNumber: {
    transform: 'translateX(-50%)',
    bottom: '4px',
    pointerEvents: 'none',
    height: '12px',
    color: '#36A6FD',
    fontSize: '0.625rem',

    [theme.breakpoints.up('hiRes')]: {
      buttom: '0.25rem',
      height: '0.75rem',
    },
  },
  bigCircle: {
    width: '20px',
    height: '20px',
    background: 'rgb(54, 166, 253, 0.2)',
    top: '40px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
      height: '1.25rem',
      top: '2.5rem',
    },
  },
  mediumCircle: {
    width: '14px',
    height: '14px',
    background: 'rgb(54, 166, 253, 0.4)',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.875rem',
      height: '0.875rem',
    },
  },
  smallCircle: {
    width: '7px',
    height: '7px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.5rem',
      height: '0.5rem',
    },
  },
  topPointImg: {
    maxWidth: '200px',

    [theme.breakpoints.up('hiRes')]: {
      maxWidth: '12.5rem',
    },
  },
}));

const charts = {
  VIEWS: 'Views',
  VIEW_DURATION: 'View Duration',
  POINTS_VIEWS: 'Point Views',
};

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 250);
viewDurationGradient.addColorStop(0, 'rgba(54,166,253,0.5)');
viewDurationGradient.addColorStop(1, 'rgba(54,166,253,0.25)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 250);
viewDurationGradientHover.addColorStop(0, 'rgba(54,166,253,1)');
viewDurationGradientHover.addColorStop(1, 'rgba(54,166,253,0.49)');

const hideTimeout = 0;

const TourOverviewView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const visitsDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;

  const { locationId } = useParams();

  const refVisits = useRef();
  const refDuration = useRef();
  const refPointVisits = useRef();
  const refDoughnut = useRef();

  const refVisitsScroll = useRef();
  const refDurationScroll = useRef();
  const initialVisitsDateValue = useRef(visitsDateValue);
  const timeout = useRef({
    show: false,
    timeout: undefined,
  });

  const [currentViewDurationEntity, setCurrentViewDurationEntity] = useState(
    GRAPH_TYPE.LOCATION,
  );

  const [isTitles, setIsTitles] = useState({});

  const [viewDurationStats, setViewDurationStats] = useState(null);
  const [filteredVisitStats, setFilteredVisitStats] = useState([]);
  const [filteredViewDurationStats, setFilteredViewDurationStats] = useState({
    [GRAPH_TYPE.LOCATION]: [],
    [GRAPH_TYPE.POINTS]: [],
  });

  const [isChartLoading, setIsChartLoading] = useState({
    visitStats: false,
    viewDurationStats: false,
  });

  const [isPdfDownloaded, setIsPdfDownloaded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const location = useAsync(() => getLocation(locationId), [locationId]);

  const summaryStats = useAsync(async () => {
    if (
      visitsDateValue &&
      visitsDateValue.length !== 0 &&
      !isEqual(visitsDateValue, initialVisitsDateValue.current)
    ) {
      setIsLoading(true);
      dispatch(changeVisible(false));

      return getSummaryStats(
        locationId,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      )
        .then(result => {
          setIsLoading(false);
          return result;
        })
        .catch(() => DEFAULT_LOCATION_STATS);
    }

    return null;
  }, [locationId, visitsDateValue]);

  const locationViewDurationStats = useAsync(
    () => getLocationViewDurationStats(locationId).catch(() => []),
    [locationId],
  );

  const locationPointsViewDurationStats = useAsync(
    () => getLocationPointViewDurationStats(locationId).catch(() => []),
    [locationId],
  );

  const locationVisitsStats = useAsync(
    () =>
      location.value
        ? getLocationVisitsStats(
            locationId,
            location.value.description.LIST_ID,
          ).catch(e => {
            console.error('LocationOverview:getLocationVisitsStats', e);
            return [];
          })
        : Promise.resolve({ loading: true, value: [] }),
    [locationId, location.value],
  );

  const pointClicksStats = useAsync(() => {
    if (visitsDateValue && visitsDateValue.length !== 0) {
      return getLocationPointClicksStats(
        locationId,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      ).catch(() => []);
    }

    return Promise.resolve([]);
  }, [locationId, visitsDateValue]);

  const locationMenuClickStats = useAsync(async () => {
    if (visitsDateValue.length === 2) {
      const stats = await getLocationMenuClickStats(
        locationId,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      ).catch(() => []);

      return stats;
    }

    return Promise.resolve({ value: [], loading: true });
  }, [locationId, visitsDateValue]);

  const [topPointId] = useMemo(() => {
    if (!Array.isArray(pointClicksStats.value)) return [];

    return [...pointClicksStats.value]
      .sort((a, b) => b.eventsCount - a.eventsCount)
      .slice(0, 1)
      .map(x => x.pointId);
  }, [pointClicksStats.value]);

  const filteredPointClicksStats = useMemo(() => {
    if (!Array.isArray(pointClicksStats.value)) return [];

    return [...pointClicksStats.value].sort(
      (a, b) => b.eventsCount - a.eventsCount,
    );
  }, [pointClicksStats.value]);

  const mobileVisitsPercent = useMemo(() => {
    if (summaryStats.value == null) return 0;

    return (
      (100 * summaryStats.value.totalVisitsMobile) /
      (summaryStats.value.totalVisitsMobile + summaryStats.value.totalVisits)
    );
  }, [summaryStats.value]);

  const desktopVisitsPercent = useMemo(() => {
    return 100 - mobileVisitsPercent;
  }, [mobileVisitsPercent]);

  const handleSavePdf = useCallback(canvas => {
    const imgData = canvas.toDataURL('image/png', 1);
    const docDefinition = {
      content: [
        {
          text: 'Button Clicks',
          style: 'header',
        },
        {
          text: "Information about the user's actions with the viewed tour",
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        calculateImgSize(imgData, canvas.width, canvas.height),
      ],
      styles: {
        header: {
          fontSize: 23,
          bold: true,
        },
      },
      pageOrientation: 'landscape',
    };

    pdfMake.createPdf(docDefinition).download();
  }, []);

  const handleSaveViewDurationInPdf = useCallback((canvas, entity) => {
    return new Promise(rs => {
      const imgDataFirst = canvas.toDataURL('image/png', 1);
      const firstW = canvas.width;
      const firstH = canvas.height;

      setCurrentViewDurationEntity(prev =>
        prev === 'locationPoints' ? 'location' : 'locationPoints',
      );

      setTimeout(() => {
        const imgDataSecond = canvas.toDataURL('image/png', 1);

        const maxHeight = 430;
        const maxWidth = 750;

        const first = calculateImgSize(
          imgDataFirst,
          firstW,
          firstH,
          maxWidth,
          maxHeight,
        );
        const second = calculateImgSize(
          imgDataSecond,
          canvas.width,
          canvas.height,
          maxWidth,
          maxHeight,
        );

        const docDefinition = {
          content: [
            {
              text:
                entity === 'location'
                  ? 'Tour View Duration'
                  : 'Point View Duration',
              style: 'header',
            },
            {
              text:
                entity === 'location'
                  ? '\nThe amount of time users spent on a tour divided by day for the specified period\n'
                  : '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            first,
            {
              text:
                entity === 'location'
                  ? 'Point View Duration'
                  : 'Tour View Duration',
              style: 'header',
              margin: [0, maxHeight - first.height, 0, 0],
            },
            {
              text:
                entity === 'location'
                  ? '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n'
                  : '\nThe amount of time users spent on a tour divided by day for the specified period\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            second,
          ],
          styles: {
            header: {
              fontSize: 23,
              bold: true,
            },
          },
          pageOrientation: 'landscape',
        };
        pdfMake.createPdf(docDefinition).download();

        setCurrentViewDurationEntity(prev =>
          prev === 'locationPoints' ? 'location' : 'locationPoints',
        );

        setIsTitles({});
        rs();
      }, 0);
    });
  }, []);

  const changeVisitsTimestampHandler = useCallback(() => {
    refVisitsScroll.current.scrollLeft = 0;
  }, []);

  const changeDurationTimestampHandler = useCallback(() => {
    refDurationScroll.current.scrollLeft = 0;
  }, []);

  useEffect(() => {
    if (
      !locationVisitsStats.loading &&
      !locationVisitsStats.value.loading &&
      locationVisitsStats.value &&
      locationVisitsStats.value[0]
    ) {
      dispatch(
        setInitialRange(
          defaultTimeRange(moment(locationVisitsStats.value[0].eventDate)),
        ),
      );
      dispatch(
        trySetRange(
          defaultTimeRange(moment(locationVisitsStats.value[0].eventDate)),
        ),
      );
    }
  }, [dispatch, locationVisitsStats]);

  useEffect(() => {
    if (
      !locationVisitsStats.loading &&
      !locationVisitsStats.value.loading &&
      locationVisitsStats.value &&
      visitsDateValue
    ) {
      const stats = filterStatsByDate(
        locationVisitsStats.value,
        visitsDateValue,
      ).slice();
      setFilteredVisitStats(stats);
    }
  }, [locationVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (
      !locationViewDurationStats.loading &&
      locationViewDurationStats.value &&
      visitsDateValue.length === 2 &&
      locationPointsViewDurationStats.value
    ) {
      setViewDurationStats({
        location: filterStatsByDate(
          locationViewDurationStats.value,
          visitsDateValue,
        )
          .map(item => {
            return {
              label: moment(item.eventDate).format('DD.MM'),
              data: item,
            };
          })
          .slice(),
        locationPoints: [...locationPointsViewDurationStats.value]
          .sort((a, b) => b.duration - a.duration)
          .map(item => {
            return {
              label: `#${item.pointId + 1}`,
              data: item,
            };
          })
          .slice()
          .reverse(),
      });
    }
  }, [
    locationPointsViewDurationStats.value,
    locationViewDurationStats.value,
    locationViewDurationStats.loading,
    locationPointsViewDurationStats.loading,
    locationViewDurationStats,
    visitsDateValue,
  ]);

  useEffect(() => {
    if (viewDurationStats && visitsDateValue) {
      const dataViewDuration = calculateEventsInIntervals({
        data: viewDurationStats[GRAPH_TYPE.LOCATION].map(({ data }) => {
          return {
            eventDate: data.eventDate,
            value: data.duration,
          };
        }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredViewDurationStats({
        [GRAPH_TYPE.LOCATION]: dataViewDuration
          .reverse()
          .map(({ eventDate, value }) => ({
            label: moment(eventDate).format('DD.MM'),
            data: {
              eventDate,
              duration: value,
            },
          })),
        [GRAPH_TYPE.POINTS]: viewDurationStats[GRAPH_TYPE.POINTS],
      });
    }
  }, [
    currentStep,
    currentViewDurationEntity,
    viewDurationStats,
    visitsDateValue,
  ]);

  useEffect(() => {
    if (locationVisitsStats.value == null || locationVisitsStats.loading) {
      return;
    }

    if (typeof locationVisitsStats.value.map !== 'function') return;

    const dataVisits = calculateEventsInIntervals({
      data: locationVisitsStats.value.map(({ eventDate, eventsCount }) => {
        return {
          eventDate,
          value: eventsCount,
        };
      }),
      range: currentStep,
      start: visitsDateValue[0].format('YYYY-MM-DD'),
      end: visitsDateValue[1].format('YYYY-MM-DD'),
    });

    setFilteredVisitStats(
      dataVisits.reverse().map(({ eventDate, value }) => ({
        eventDate,
        eventsCount: value,
      })),
    );
  }, [currentStep, locationVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (!isTitles.chart || isTitles.chart === charts.VIEW_DURATION) return;
    const { canvas, chart, text } = isTitles;

    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);

      const docDefinition = {
        content: [
          {
            text: chart,
            style: 'header',
          },
          {
            text,
            fontSize: 10,
            margin: [0, 0, 0, 10],
          },
          calculateImgSize(imgData, canvas.width, canvas.height),
        ],
        styles: {
          header: {
            fontSize: 35,
            bold: true,
          },
        },
        pageOrientation: 'landscape',
      };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  useEffect(() => {
    async function downloadPdf() {
      if (isTitles.chart !== charts.VIEW_DURATION || !isPdfDownloaded) return;
      const { canvas } = isTitles;
      setIsPdfDownloaded(false);
      await handleSaveViewDurationInPdf(canvas, currentViewDurationEntity);
      setIsPdfDownloaded(true);
    }

    downloadPdf();
  }, [
    currentViewDurationEntity,
    handleSaveViewDurationInPdf,
    isPdfDownloaded,
    isTitles,
  ]);

  useEffect(() => {
    initialVisitsDateValue.current = visitsDateValue;
  }, [visitsDateValue]);

  if (
    !location.value ||
    !locationPointsViewDurationStats.value ||
    !locationVisitsStats.value
  ) {
    return <React.Fragment />;
  }

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div
          style={{ display: 'flex', alignItems: 'center', color: '#6C6C6C' }}
        >
          <div
            role="button"
            onClick={() => navigate(-1)}
            className={classes.backIcon}
          />
          <div>
            <div style={{ fontSize: '1.5rem' }}>
              {/* <NavLink to={`/overview/${tour.id}`}>{tour.title}</NavLink> /{' '} */}
              <span style={{ color: '#333' }}>
                <span style={{ color: '#6c6c6c' }}>
                  <Link
                    to={`/overview/multi/${location.value.company_name}/${location.value.list_id}`}
                  >
                    {location.value.description.LIST_ID}
                  </Link>
                  /
                </span>{' '}
                {location.value.name}
              </span>
            </div>
            <div style={{ fontWeight: 400, fontSize: '0.875rem' }}>
              Individual location statistics within the Virtual Tour
            </div>
          </div>
        </div>
      </Header>
      <div className="w-full flex">
        <div
          className="w-7/12 flex gap-4"
          style={{ padding: '15px 0px 10px 0' }}
        >
          <p
            style={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              paddingTop: '5.5px',
            }}
          >
            Embed code
          </p>
          <CopyInput
            value={
              locationId &&
              `<iframe allowfullscreen title="view_player" src="${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${locationId}" width="100%" height="500" />`
            }
          />
          <div>
            <a
              style={{
                padding: '3.5px',
                borderRadius: '5px',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px',
                outline: 'none',
                border: '0px',
                textOverflow: 'ellipsis',
                fontSize: '0.7rem',
                whiteSpace: 'nowrap',
                background: 'white',
              }}
              target="_blank"
              href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${locationId}`}
              rel="noreferrer"
            >
              Launch the tour
            </a>
          </div>
          <div style={{ paddingBlock: '.3rem' }}>
            <MultiDateRangePicker />
          </div>
        </div>
      </div>
      <div
        className="w-full flex flex-wrap"
        style={{
          width: 'calc(100% + 4px)',
          marginLeft: '-4px',
        }}
      >
        <InfoCard
          className="px-1"
          title="Total Views"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalVisits +
                  summaryStats.value.totalVisitsMobile !==
                0
              ? summaryStats.value.totalVisits +
                summaryStats.value.totalVisitsMobile
              : -1
          }
        />
        <InfoCard
          className="px-1"
          title="Avg. Views Per Day"
          fullTitle="Average Views Per Day"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.avgVisitsPerDay !== 0
              ? renderDecimal(summaryStats.value.avgVisitsPerDay)
              : -1
          }
        />
        <InfoCard
          className="px-1"
          title="Avg. Views Per Month"
          fullTitle="Average Views Per Month"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.avgVisitsPerMonth !== 0
              ? renderDecimal(summaryStats.value.avgVisitsPerMonth)
              : -1
          }
        />
        <InfoCard
          className="px-1"
          title="Total Duration"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalDuration !== 0
              ? formatDateFromSeconds(summaryStats.value.totalDuration)
              : '00:00'
          }
          tooltipText="Total Duration of viewing of the Virtual Tour"
        />
        <InfoCard
          className="px-1"
          title="Avg. View Duration"
          fullTitle="Average View Duration"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.avgVisitDuration !== 0
              ? formatDateFromSeconds(summaryStats.value.avgVisitDuration)
              : '00:00'
          }
        />
        <InfoCard
          className="px-1"
          title="Conversion"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalCustomButtonClicks !== 0
              ? summaryStats.value.totalCustomButtonClicks
              : -1
          }
          tooltipText="Amounts of clicks to Lease Now button at the bottom right corner"
        />
        <InfoCard
          className="px-1"
          title="Custom Button Clicks"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalHotspotButtonClicks !== 0
              ? summaryStats.value.totalHotspotButtonClicks
              : -1
          }
          tooltipText="Amounts of clicks on the all buttons inside informational hotspots"
        />
      </div>

      <div
        className="w-full flex mt-1 mb-2"
        style={{ borderRadius: '10px', minHeight: '273px' }}
      >
        <div
          className="w-1/2 h-auto mr-1 bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex justify-between flex-wrap"
            style={{ paddingTop: 18 }}
          >
            <div
              className="flex"
              style={{
                marginBottom: 18,
                fontSize: '0.75rem',
                alignItems: 'center',
              }}
            >
              <Tooltip
                placement="top"
                title="Amount of views applied range of times"
              >
                <span>Views</span>
              </Tooltip>
              <div
                role="button"
                className={classes.upload}
                onClick={() =>
                  setIsTitles({
                    canvas: refVisits.current.chartInstance.canvas,
                    chart: charts.VIEWS,
                    text: '\nTotal number of views for the specified period',
                  })
                }
              >
                <img src="/static/icons/upload.svg" alt="Upload" />
              </div>
            </div>
            <div
              className="flex"
              style={{ marginBottom: '18px', fontSize: '12px' }}
            >
              <TimeStep
                // setCurrentStep={setCurrentStepVisits}
                visitsDateValue={visitsDateValue}
                onClick={changeVisitsTimestampHandler}
              />
            </div>
          </div>

          <div
            className={`w-full relative flex flex-col ${classes.h12}`}
            ref={refVisitsScroll}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  filteredVisitStats.length * calculateChartSize(currentStep),
                overflowY: 'overlay',
              }}
            >
              {!isChartLoading.visitStats ? (
                <Line
                  ref={refVisits}
                  data={{
                    datasets: [
                      {
                        backgroundColor: gradient,
                        lineTension: 0.01,
                        borderColor: '#36A6FD',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        pointRadius: size(15),
                        pointHoverRadius: size(15),
                        borderWidth: 1,
                        data: filteredVisitStats.map(item => item.eventsCount),
                        label: '',
                      },
                    ],
                    labels: formatLabels(filteredVisitStats, currentStep),
                  }}
                  options={{
                    animation: false,
                    cornerRadius: size(20),
                    layout: {
                      padding: {
                        right: size(13),
                      },
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,
                    devicePixelRatio: window.devicePixelRatio * 2,

                    hover: {
                      onHover(e, [chart]) {
                        if (!chart) {
                          dispatch(
                            updateVisitsTooltipInfo({
                              show: false,
                              showDashes: false,
                              showLabel: false,
                              showCircle: false,
                            }),
                          );
                          return;
                        }

                        const value = filteredVisitStats[chart._index];

                        dispatch(
                          setVisitsTooltipInfo({
                            locationId,
                            showCircle: true,
                            showDashes: true,
                            locationName: location.value.name,
                            type: TOOLTIP_TYPE.SMALL,
                            show: true,
                            pointId: value.pointId,
                            label: chart._xScale.ticks[chart._index],
                            smallTooltipText: `${value.eventsCount}`,
                            dashesHeight: `${
                              refVisits.current.chartInstance.height - 20
                            }px`,
                            showLabel: true,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            tooltipTop: chart._view.y + 28,
                            circleLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              10,
                            circleTop: chart._view.y + 50,
                            dashesLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            dashesTop:
                              e.target.parentElement.parentElement.offsetTop,
                            labelLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            labelBottom: 15,
                          }),
                        );
                      },
                    },
                    tooltips: {
                      enabled: false,
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: size(8),
                            reverse: true,
                          },
                          categoryPercentage: 0.97,
                          barPercentage: 0.97,
                          barThickness: 28,
                          scaleLabel: {
                            display: true,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontStyle: 'bold',
                            fontColor: '#000',
                            stepSize: 250,
                            beginAtZero: true,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: 'Views',
                          },
                        },
                      ],
                    },
                  }}
                />
              ) : (
                <Skeleton height="99%" />
              )}
            </div>
          </div>
          <GraphTooltip store="visitsTooltip" />
        </div>

        <div
          className="w-1/2 h-auto mr-1 bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex justify-between flex-wrap"
            style={{ paddingTop: 18 }}
          >
            <div
              className="flex"
              style={{
                marginBottom: 18,
                fontSize: '0.75rem',
                alignItems: 'center',
              }}
            >
              Button Clicks
              <div
                role="button"
                className={classes.upload}
                onClick={() =>
                  handleSavePdf(refDoughnut.current.chartInstance.canvas)
                }
              >
                <img src="/static/icons/upload.svg" alt="Upload" />
              </div>
            </div>
          </div>

          <div
            className={`w-full relative flex flex-col ${classes.h12}`}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                overflowY: 'overlay',
              }}
            >
              <Doughnut
                ref={refDoughnut}
                data={{
                  datasets: [
                    {
                      // fill:'start',
                      backgroundColor: (locationMenuClickStats.value || []).map(
                        x => x.color,
                      ),
                      // backgroundColor: colors.blue[500],
                      data: (locationMenuClickStats.value || []).map(
                        x => x.count,
                      ),
                      datalabels: {
                        labels: {
                          value: {},
                          name: {
                            align: 'end',
                            anchor: 'end',
                            offset: -1,
                            font: { size: 1 },
                            padding: 0,
                            backgroundColor: '#A0A0A0',
                            color: '#A0A0A0',
                            formatter() {
                              return '................................................................................................';
                            },
                            rotation(value) {
                              const sum = value.dataset.data.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                              );
                              const position =
                                value.dataset.data
                                  .slice(0, value.dataIndex + 1)
                                  .reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + currentValue,
                                  ) -
                                value.dataset.data[value.dataIndex] / 2;
                              return (position / sum) * 360 - 90;
                            },
                          },
                        },
                      },
                    },
                  ],
                  labels: (locationMenuClickStats.value || []).map(x => x.name),
                }}
                options={{
                  maintainAspectRatio: false,
                  devicePixelRatio: window.devicePixelRatio * 2,
                  legend: {
                    display: true,
                    position: document.body.offsetWidth > 1500 ? 'left' : 'top',
                    labels: {
                      fontSize: size(12),
                    },
                  },
                  layout: {
                    padding: 50,
                  },
                  scales: {
                    xAxes: [{ display: false }],
                    yAxes: [{ display: false }],
                  },
                  pieceLabel: {
                    render: 'label',
                    fontColor: '#000',
                    position: 'outside',
                  },
                  plugins: {
                    datalabels: {
                      anchor: 'end',
                      align: 'end',
                      offset: 22,
                      color: '#A0A0A0',
                      font: {
                        size: size(12),
                      },
                    },
                  },
                }}
                plugins={[
                  ChartDataLabels,
                  {
                    beforeInit(chart, options) {
                      chart.legend.afterFit = function () {
                        this.height += 50;
                        this.legendHitBoxes.forEach(element => {
                          element.left = 0;
                        });
                      };
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full flex mb-3"
        style={{ borderRadius: '10px', minHeight: '273px' }}
      >
        <div
          className="w-1/2 h-auto mr-1 bg-white relative flex flex-col"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            style={{
              visibility: isPdfDownloaded ? 'visible' : 'hidden',
            }}
          >
            <div
              className="flex justify-between flex-wrap"
              style={{ marginTop: 18 }}
            >
              <div style={{ marginBottom: 18 }} className="flex">
                <Tooltip
                  placement="top"
                  title="The amount of time the tour was viewed per day"
                >
                  <span
                    role="button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className={cn({
                      [classes.viewDurationSetter]: true,
                      active: currentViewDurationEntity === GRAPH_TYPE.LOCATION,
                    })}
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentViewDurationEntity(GRAPH_TYPE.LOCATION);
                    }}
                  >
                    Location View Duration
                  </span>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="The amount of time each 360 point was viewed"
                >
                  <span
                    role="button"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className={cn({
                      [classes.viewDurationSetter]: true,
                      active: currentViewDurationEntity === 'locationPoints',
                    })}
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentViewDurationEntity('locationPoints');
                    }}
                  >
                    Point View Duration
                  </span>
                </Tooltip>
                <div
                  className={classes.upload}
                  role="button"
                  onClick={() =>
                    setIsTitles({
                      canvas: refDuration.current.chartInstance.canvas,
                      chart: charts.VIEW_DURATION,
                      text: 'The amount of time users spent on a tour divided by day for the specified period',
                    })
                  }
                >
                  <img src="/static/icons/upload.svg" alt="Upload" />
                </div>
              </div>
              {currentViewDurationEntity === 'location' && (
                <div
                  className="flex"
                  style={{ marginBottom: '18px', fontSize: '12px' }}
                >
                  <TimeStep
                    // setCurrentStep={setCurrentStepViewDuration}
                    visitsDateValue={visitsDateValue}
                    onClick={changeDurationTimestampHandler}
                  />
                </div>
              )}
            </div>

            <div
              className={`relative ${classes.h14}`}
              ref={refDurationScroll}
              style={{
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  minWidth:
                    currentViewDurationEntity === 'location'
                      ? filteredViewDurationStats[currentViewDurationEntity]
                          .length * calculateChartSize(currentStep)
                      : filteredViewDurationStats[currentViewDurationEntity]
                          .length * 32,
                  overflowY: 'overlay',
                }}
              >
                {!isChartLoading.viewDurationStats ? (
                  <Bar
                    ref={refDuration}
                    data={{
                      labels: filteredViewDurationStats[
                        currentViewDurationEntity
                      ].map(x => x.label),
                      datasets: [
                        {
                          backgroundColor: viewDurationGradient,
                          hoverBackgroundColor: viewDurationGradientHover,
                          data: filteredViewDurationStats[
                            currentViewDurationEntity
                          ].map(x => Math.round(x.data.duration / 60)),
                          label: '',
                          datasetLabel: '',
                        },
                      ],
                    }}
                    options={{
                      animation: false,
                      cornerRadius: 20,
                      layout: { padding: 0 },
                      legend: { display: false },
                      maintainAspectRatio: false,
                      responsive: true,
                      devicePixelRatio: window.devicePixelRatio * 1.5,

                      onHover(e, [chart]) {
                        if (!chart) {
                          if (currentViewDurationEntity === GRAPH_TYPE.POINTS) {
                            if (timeout.current.show) {
                              clearTimeout(timeout.current);

                              timeout.current.timeout = setTimeout(() => {
                                dispatch(hideDurationTooltip());
                                timeout.current.show = false;
                              }, hideTimeout);
                            }
                          } else {
                            dispatch(hideDurationTooltip());
                          }

                          return;
                        }

                        const value =
                          filteredViewDurationStats[currentViewDurationEntity][
                            chart._index
                          ].data;

                        dispatch(
                          setDurationTooltipInfo({
                            locationId,
                            locationName: location.value.name,
                            show: true,
                            bigTooltipText: `Duration: ${moment
                              .utc(value.duration * 1000)
                              .format('HH:mm:ss')}`,
                            type:
                              currentViewDurationEntity === GRAPH_TYPE.LOCATION
                                ? TOOLTIP_TYPE.SMALL
                                : TOOLTIP_TYPE.BIG,
                            pointId: value.pointId,
                            smallTooltipText: `${Math.round(
                              value.duration / 60,
                            )}m`,
                            tooltipTop:
                              currentViewDurationEntity === GRAPH_TYPE.LOCATION
                                ? chart._view.y +
                                  e.target.parentElement.parentElement
                                    .offsetTop -
                                  30
                                : chart._view.y + 63,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                          }),
                        );
                        timeout.current.show = true;
                      },
                      tooltips: {
                        enabled: false,
                        intersect: false,
                      },
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              fontSize: size(8),
                              reverse: true,
                            },
                            categoryPercentage: 0.97,
                            barPercentage: 0.97,
                            barThickness:
                              currentViewDurationEntity === 'location'
                                ? calculateChartSize(currentStep) - 2
                                : 30,
                            scaleLabel: {
                              display: true,
                              labelString:
                                currentViewDurationEntity === 'location'
                                  ? 'Date'
                                  : 'Point',
                            },
                          },
                        ],
                        yAxes: [
                          {
                            // afterFit(scaleInstance) {
                            //   setViewDurationChartScaleWidth(scaleInstance.width);
                            // },
                            ticks: {
                              fontStyle: 'bold',
                              fontColor: '#000',
                              stepSize: 250,
                              beginAtZero: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Time (m)',
                            },
                          },
                        ],
                      },
                    }}
                  />
                ) : (
                  <Skeleton height="99%" />
                )}
              </div>
            </div>
            <GraphTooltip
              store="durationTooltip"
              updateFunc={updateDurationTooltipInfo}
              hideFunc={hideDurationTooltip}
            />
          </div>
          {!isPdfDownloaded && (
            <Skeleton
              height="100%"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: '10px',
              }}
            />
          )}
        </div>

        <div
          className="w-1/2 h-auto ml-1 bg-white relative flex flex-col"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex items-center"
            style={{ padding: '19px 0', fontSize: '0.75rem' }}
          >
            <div>
              <Tooltip
                placement="top"
                title="The amount of views of each individual 360 point"
              >
                <span>Point Views</span>
              </Tooltip>
            </div>
            <Tooltip placement="top" title="Download the graph">
              <div
                role="button"
                className={classes.upload}
                onClick={() =>
                  setIsTitles({
                    canvas: refPointVisits.current.chartInstance.canvas,
                    chart: charts.POINTS_VIEWS,
                    text: '\nThe number of views to the tour points divided by the number of point views',
                  })
                }
              >
                <img src="/static/icons/upload.svg" alt="Upload" />
              </div>
            </Tooltip>
          </div>
          <div
            className={`relative ${classes.h14}`}
            style={{ overflow: 'auto' }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth: filteredPointClicksStats.length * size(32),
                overflowY: 'overlay',
              }}
            >
              <Bar
                ref={refPointVisits}
                data={{
                  labels: filteredPointClicksStats.map(
                    item => `#${item.pointId + 1}`,
                  ),
                  datasets: [
                    {
                      backgroundColor: viewDurationGradient,
                      hoverBackgroundColor: viewDurationGradientHover,
                      data: filteredPointClicksStats.map(
                        item => item.eventsCount,
                      ),
                      label: '',
                      datasetLabel: '',
                    },
                  ],
                }}
                options={{
                  animation: false,
                  cornerRadius: 20,
                  layout: { padding: 0 },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 2,

                  onHover(e, [chart]) {
                    if (!chart) {
                      if (timeout.current.show) {
                        clearTimeout(timeout.current);

                        timeout.current.timeout = setTimeout(() => {
                          dispatch(hidePointVisitsTooltip());
                          timeout.current.show = false;
                        }, hideTimeout);
                      }

                      return;
                    }

                    const value = filteredPointClicksStats[chart._index];
                    const { data } = filteredViewDurationStats[
                      GRAPH_TYPE.POINTS
                    ].find(item => item.data.pointId === value.pointId);

                    dispatch(
                      setPointsVisitTooltipInfo({
                        locationId,
                        locationName: location.value.name,
                        show: true,
                        scrollX:
                          e.target.parentElement.parentElement.scrollLeft,
                        offsetY: e.target.parentElement.parentElement.offsetTop,
                        caretX: chart._view.x,
                        caretY: chart._view.y,
                        pointId: value.pointId,
                        center: true,
                        label: chart._xScale.ticks[chart._index],
                        type: TOOLTIP_TYPE.BIG,
                        bigTooltipText: (
                          // eslint-disable-next-line react/jsx-indent
                          <div style={{ fontSize: '0.6rem' }}>
                            <div>Clicks: {value.eventsCount}</div>
                            <div>
                              Duration:{' '}
                              {moment.utc(data.duration * 1000).format('HH:mm')}
                            </div>
                          </div>
                        ),
                        tooltipTop: chart._view.y + 65,
                        tooltipLeft:
                          chart._view.x -
                          e.target.parentElement.parentElement.scrollLeft +
                          20,
                      }),
                    );
                    timeout.current.show = true;
                  },
                  tooltips: {
                    enabled: false,
                    intersect: true,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: { fontSize: size(8) },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness: 28,
                        scaleLabel: {
                          display: true,
                          labelString: 'Point',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        // afterFit(scaleInstance) {
                        //   setClicksChartScaleWidth(scaleInstance.width);
                        // },
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 300,
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Clicks',
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
          <GraphTooltip
            store="pointsVisitsTooltip"
            hideFunc={hidePointVisitsTooltip}
            updateFunc={updatePointsVisitTooltipInfo}
          />
        </div>
      </div>

      <div className="w-full flex">
        {typeof topPointId === 'number' && (
          <TopPoint
            locationId={locationId}
            topPointId={topPointId}
            parentId={location.value.description.PARENT_VARIANT}
          />
        )}
        <div className="w-2/3 pl-4">
          <ViewsByDevices
            mobilePercent={mobileVisitsPercent}
            desktopPercent={desktopVisitsPercent}
            mobileCount={
              (summaryStats.value || DEFAULT_LOCATION_STATS).totalVisitsMobile
            }
            desktopCount={
              (summaryStats.value || DEFAULT_LOCATION_STATS).totalVisits
            }
          />
        </div>
      </div>
    </Page>
  );
};

export default TourOverviewView;
