import { createSlice } from '@reduxjs/toolkit';

const recentlyViewedSlice = createSlice({
  name: 'recentlyViewed',
  initialState: { loaded: false, value: [], ids: {} },
  reducers: {
    load: (state, action) => {
      for (const { locationId, listId } of action.payload) {
        state.ids[locationId || listId] = true;
      }

      state.value = action.payload;
      state.loaded = true;
    },

    push: (state, action) => {
      const { locationId, listId } = action.payload;
      if (!state.ids[locationId || listId]) {
        state.ids[locationId || listId] = true;
        state.value.unshift(action.payload);
        if (state.value.length > 10) {
          state.value.pop();
        }
      }
    },
  },
});

export const { load, push } = recentlyViewedSlice.actions;
export default recentlyViewedSlice.reducer;
