import React, { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

import {
  getMultiLocationStats,
  getSummaryStats,
  buildLocationsForUser,
  buildLocationsForAdmin,
  getCompaniesFullStats,
} from '~/services/api';
import { authRoles } from '~/constants';
import TourCard from '../components/TourCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',

    [theme.breakpoints.up('hiRes')]: {
      paddingLeft: '2rem',
      paddingRight: '1.3rem',
    },
  },
  cardContainer: {
    paddingTop: '30px',
    paddingRight: '40px',
    paddingBottom: '30px',
    maxWidth: '100%',

    [theme.breakpoints.up('hiRes')]: {
      paddingRight: '0',
    },
  },
  header: {
    fontSize: '1.5rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1.33rem',
    },
  },
  header2: {
    fontSize: '0.875rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },
  },
  grid: {
    flexBasis: '33.3%',
    minWidth: 200,
    flexGrow: 1,
  },
}));

const fetchTourStats = tour => {
  const mainLocation =
    tour.locations.find(l => l.description.IS_MAIN) || tour.locations[0];
  if (mainLocation.name !== tour.multilocationName) {
    return getMultiLocationStats(tour.multilocationName, tour.companyName);
  }
  return getSummaryStats(mainLocation.id);
};

const visitSum = tour => tour.stats.totalVisits + tour.stats.totalVisitsMobile;

const GalleryView = () => {
  const search = useSelector(state => state.gallery.search);
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const isAdmin = user.role === authRoles.ADMIN;

  // const locations = useAsync(() => buildLocationsForUser(user.company), [
  //   user.company,
  // ]);

  const locations = useAsync(async () => {
    if (isAdmin) {
      return getCompaniesFullStats();
    }

    const tourList = (await buildLocationsForUser(user.company)) || [];

    return Promise.all(
      tourList
        .filter(t => t.locations.length > 0)
        .map(async tour => {
          const stats = (await fetchTourStats(tour)) || {};
          return { ...tour, stats };
        }),
    );
  }, [isAdmin]);

  const multilocations = useMemo(
    () => (locations.value || []).sort((a, b) => visitSum(b) - visitSum(a)),
    [locations],
  );

  const filteredMultilocations = useMemo(() => {
    return multilocations.filter(v =>
      v.multilocationName.toLowerCase().includes(search.toLowerCase()),
    );
  }, [multilocations, search]);

  if (locations.loading) return null;

  return (
    <Page className={classes.root} title="Gallery">
      <Header>
        <div className={classes.header}>All of your tours in one place!</div>
        <div className={classes.header2}>Choose any tour to view your data</div>
      </Header>

      <Grid
        container
        spacing={4}
        className={`transform-gpu ${classes.cardContainer}`}
      >
        {filteredMultilocations.map(multilocation => (
          <Grid
            key={multilocation.id}
            item
            lg={4}
            md={6}
            xs={12}
            className={classes.grid}
          >
            <TourCard tour={multilocation} />
          </Grid>
        ))}
        {new Array(6 - (filteredMultilocations.length % 6))
          .fill(null)
          .map((crutch, index) => (
            <Grid
              key={index}
              item
              lg={4}
              md={6}
              xs={12}
              className={classes.grid}
              style={{
                padding: 0,
              }}
            />
          ))}
      </Grid>
    </Page>
  );
};

export default GalleryView;
